import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Page from "../Components/Page";
import {
    Checkbox,
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import Loading from "../Components/Loading";
import {
    PageImportQuery,
    PageImportQueryVariables,
} from "../graphql/query-types";
import useFavorite from "../features/personalSchedule/hooks/useFavorite";
import Notes from "../features/personalSchedule/models/Notes";
import { NoteContext } from "../features/personalSchedule/context/NoteContext";
import useSettings from "../features/settings/hooks/useSettings";

const StyledNested = styled(List)({
    paddingLeft: "1.5rem",
});

const StyledDenseCheckbox = styled(Checkbox)({
    padding: 0,
});

const query = loader("../graphql/PageImport.graphql");

interface FetchData {
    favorites?: number[];
    notes?: Notes;
}

export default function Import() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const uuid = params.get("uuid");
    const settings = useSettings();
    const history = useHistory();

    const [importFavorites, setImportFavorites] = useState<number[]>([]);
    const [importNotes, setImportNotes] = useState<number[]>([]);
    const [fetchData, setFetchData] = useState<FetchData | undefined>(
        undefined
    );
    const [expand, setExpand] = useState(-1);
    const favorites = useFavorite(importFavorites);
    const [notes, setNotes] = useContext(NoteContext) ?? [null, null];

    const noteIds = Object.keys(fetchData?.notes || {}).map(n => parseInt(n));
    const noteIdsFiltered = noteIds?.filter(
        (id) => !fetchData?.favorites?.includes(id)
    );
    const allPaperIds = [
        ...(fetchData?.favorites ?? []),
        ...(noteIdsFiltered ?? []),
    ];

    const { loading, error, data } = useQuery<
        PageImportQuery,
        PageImportQueryVariables
    >(query, {
        variables: { ids: allPaperIds },
    });

    useEffect(() => {
        fetch(settings.dataServer + "/data/" + uuid)
            .then((result) => result.json())
            .then((result) => {
                setFetchData(result);
            });
    }, [uuid, settings.dataServer]);

    function startImport() {
        if (notes && setNotes) {
            const tempNotes: Notes = {...notes};
            importNotes.forEach(importNoteId => {
                if (tempNotes[importNoteId]) {
                    // TODO Notify on Collision
                }
                if (fetchData?.notes?.[importNoteId]) {
                    tempNotes[importNoteId] = fetchData.notes[importNoteId];
                }
            });
            setNotes(tempNotes);
        }

        favorites?.add();
        history.replace("/");
    }

    function toggleFavorite(id: number) {
        const index = importFavorites.indexOf(id);
        if (index < 0) {
            setImportFavorites([...importFavorites, id]);
        } else {
            importFavorites.splice(index, 1);
            setImportFavorites([...importFavorites]);
        }
    }

    function toggleAllFavorites() {
        if (importFavorites.length > 0) {
            setImportFavorites([]);
        } else {
            setImportFavorites([...(fetchData?.favorites ?? [])]);
        }
    }

    function toggleNote(id: number) {
        const index = importNotes.indexOf(id);
        if (index < 0) {
            setImportNotes([...importNotes, id]);
        } else {
            importNotes.splice(index, 1);
            setImportNotes([...importNotes]);
        }
    }

    function toggleAllNotes() {
        if (importNotes.length > 0) {
            setImportNotes([]);
        } else {
            setImportNotes([...noteIds]);
        }
    }

    if (error) return <span>Error loading Data</span>;
    if (loading || !data) return <Loading />;

    return (
        <Page>
            <List>
                {fetchData?.favorites ? (
                    <ListItem
                        onClick={() => setExpand(expand === 0 ? -1 : 0)}
                        button
                    >
                        <ListItemIcon>
                            <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                indeterminate={
                                    importFavorites.length <
                                        fetchData.favorites.length &&
                                    importFavorites.length > 0
                                }
                                checked={importFavorites.length > 0}
                                onChange={toggleAllFavorites}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Favorites" />
                        {expand === 0 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                ) : (
                    <></>
                )}
                <Collapse in={expand === 0}>
                    <StyledNested dense={true}>
                        {data.papers
                            ?.filter((p) =>
                                fetchData?.favorites?.includes(p?.id ?? 0)
                            )
                            .map((paper, i) => (
                                <ListItem
                                    onClick={() =>
                                        paper?.id
                                            ? toggleFavorite(paper?.id)
                                            : ""
                                    }
                                    key={i}
                                    dense
                                >
                                    <ListItemIcon>
                                        <StyledDenseCheckbox
                                            checked={importFavorites.includes(
                                                paper?.id ?? 0
                                            )}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={paper?.unique} />
                                </ListItem>
                            ))}
                    </StyledNested>
                </Collapse>

                {fetchData?.notes ? (
                    <ListItem
                        onClick={() => setExpand(expand === 1 ? -1 : 1)}
                        button
                    >
                        <ListItemIcon>
                            <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                indeterminate={
                                    importNotes.length <
                                        noteIds.length &&
                                    importNotes.length > 0
                                }
                                checked={importNotes.length > 0}
                                onChange={toggleAllNotes}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Notes" />
                        {expand === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                ) : (
                    <></>
                )}
                <Collapse in={expand === 1}>
                    <StyledNested dense={true}>
                        {data.papers
                            ?.filter((p) => !!(notes && p?.id && notes[p.id]))
                            .map((paper) => (
                                <ListItem
                                    onClick={() =>
                                        paper?.id ? toggleNote(paper?.id) : ""
                                    }
                                    dense
                                >
                                    <ListItemIcon>
                                        <StyledDenseCheckbox
                                            checked={importNotes.includes(
                                                paper?.id ?? 0
                                            )}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={paper?.unique} />
                                </ListItem>
                            ))}
                    </StyledNested>
                </Collapse>

                <Button
                    color="primary"
                    variant="contained"
                    onClick={startImport}
                >
                    Import
                </Button>
            </List>
        </Page>
    );
}
