import { useState } from "react";
import Filter, { FilterEntry } from "../Components/Filter";

export default function useFilter<T>(
    title: string,
    items: FilterEntry<T>[]
): [JSX.Element, T[]] {
    const [selected, setSelected] = useState<T[]>([]);

    const filter = (
        <Filter
            entrys={items}
            onChange={setSelected}
            selected={selected}
            title={title}
        />
    );

    return [filter, selected];
}
