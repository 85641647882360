import { Typography, styled, List, ListItemButton } from "@mui/material";
import Page from "../Components/Page";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useRoutes from "../Routes";
import useSettings from "../features/settings/hooks/useSettings";

const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 64,
    margin: "32px 16px",
});

const StyledImage = styled("img")({
    flexGrow: 0,
});

const StyledTitle = styled("div")({
    display: "flex",
    flexDirection: "row",
    gap: 32,
    alignItems: "start",
    flexWrap: "wrap",
});

const StyledMenu = styled("div")({
    display: "flex",
    gap: 32,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
});

export default function Home() {
    const settings = useSettings();
    const routes = useRoutes();

    return (
        <Page title="COSPAR App">
            <StyledRoot>
                <StyledTitle>
                    <StyledImage
                        src={`${settings.dataServer}/upload/${settings.homeIcon}`}
                        alt="Congress Logo"
                        style={{ width: settings.homeIconWidth }}
                    />
                    <div>
                        <Typography variant="h4">
                            {settings.homeTitle}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            {settings.homeSubTitle}
                        </Typography>
                    </div>
                </StyledTitle>
                <StyledMenu>
                    <div>
                        <Typography>Featured Pages</Typography>
                        <List>
                            {routes
                                ?.filter(
                                    (route) => route.category === "featured"
                                )
                                .map((route) => {
                                    const route_path = Array.isArray(route.path)
                                        ? route.path[
                                                route.path.length - 1
                                            ]
                                        : route.path;

                                    return (
                                        <ListItemButton
                                            component={Link}
                                            to={route_path}
                                            key={route_path}
                                        >
                                            <ChevronRightIcon />
                                            {route.title}
                                        </ListItemButton>
                                    )
                                })}
                            {settings.featured_links?.map((fl) => (
                                <ListItemButton
                                    component="a"
                                    href={fl.link}
                                    key={fl.link}
                                >
                                    <ChevronRightIcon />
                                    {fl.label}
                                </ListItemButton>
                            ))}
                        </List>
                    </div>
                    <div>
                        <Typography>Further Information</Typography>
                        <List>
                            {routes
                                ?.filter((route) => route.category === "static")
                                .map((route) => {
                                    const route_path = Array.isArray(route.path)
                                        ? route.path[
                                            route.path.length - 1
                                        ] : route.path;

                                    return (
                                        <ListItemButton
                                            component={Link}
                                            to={route_path}
                                            key={route_path}
                                        >
                                            <ChevronRightIcon />
                                            {route.title}
                                        </ListItemButton>
                                    )
                                })}
                        </List>
                    </div>
                </StyledMenu>
            </StyledRoot>
        </Page>
    );
}
