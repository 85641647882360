import { useContext, useEffect, useState } from "react";
import { NavBarRefContext } from "../App";

export default function useNavbarHeight(): number {
    const navbarRef = useContext(NavBarRefContext);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (navbarRef?.current) {
            setHeight(navbarRef.current.getBoundingClientRect().height ?? 0);
            const obs = new ResizeObserver(() => {
                setHeight(navbarRef?.current?.getBoundingClientRect().height ?? 0);
            });
            obs.observe(navbarRef.current);

            return () => {
                obs.disconnect();
            }
        }
    }, [navbarRef]);

    return height;
}