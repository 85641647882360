import { Button, LinearProgress, styled, Typography } from "@mui/material";
import { useState } from "react";
import Page from "../../Components/Page";
import SwipeCard, { SwipeCardEvent } from "../../Components/Swipe/SwipeCard";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import {
    PageSwipeQuery,
    PageSwipeQueryVariables,
} from "../../graphql/query-types";

const query = loader("../../graphql/PageSwipe.graphql");

const StyledTopBar = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
});

const StyledProgress = styled("div")({
    position: "relative",
    marginBottom: "0.3rem",
});

const StyledProgressBar = styled(LinearProgress)({
    height: "1.4rem",
    borderRadius: "0.7rem",
});

const StyledProgressText = styled(Typography)({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: "0.8rem",
    lineHeight: 1.75,
    fontWeight: 600,
    color: "white",
});

const StyledExitButtons = styled("div")({
    display: "flex",
    marginTop: "2rem",
    justifyContent: "space-around",
});

export default function SwipeSelector() {
    const location = useLocation<{ ids?: number[] }>();
    const [current, setCurrent] = useState(0);
    const ids = location.state?.ids;
    const history = useHistory();

    const { data } = useQuery<PageSwipeQuery, PageSwipeQueryVariables>(query, {
        variables: { ids },
        skip: !ids?.length,
    });

    if (!ids?.length) {
        return (
            <Page padding>
                <Typography>No Data</Typography>
            </Page>
        );
    }

    const favorise = () => {
        /*changeUserData({
            action: "add",
            favorite: ids[current],
        });*/
        nextCard();
    };

    const dismiss = () => {
        nextCard();
    };

    const nextCard = () => {
        setCurrent((cur) => (cur < ids.length ? cur + 1 : ids.length));
    };

    const swipped = (e: SwipeCardEvent) => {
        if (e.direction === "left") {
            dismiss();
        } else {
            favorise();
        }
    };

    // 3fb55d
    const notfinished = current < ids.length;
    const progress = notfinished
        ? `(${current + 1} / ${ids.length})`
        : "Finished";

    return (
        <Page padding>
            <StyledTopBar>
                <Button startIcon={<ChevronLeft />} onClick={dismiss}>
                    Dismiss
                </Button>
                <Typography variant="body2" color="textSecondary">
                    Swipe to select
                </Typography>
                <Button endIcon={<ChevronRight />} onClick={favorise}>
                    Favorite
                </Button>
            </StyledTopBar>
            <StyledProgress>
                <StyledProgressBar
                    value={(current / ids.length) * 100}
                    variant="determinate"
                />
                <StyledProgressText>{progress}</StyledProgressText>
            </StyledProgress>
            {!notfinished && (
                <StyledExitButtons>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.replace("/ps")}
                    >
                        Personal Schedule
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.goBack()}
                    >
                        Return to previous page
                    </Button>
                </StyledExitButtons>
            )}
            {data?.papers?.map(
                (paper, i) =>
                    i === current && (
                        <SwipeCard paper={paper} key={i} onSwipe={swipped} />
                    )
            )}
        </Page>
    );
}
