import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogActions, DialogProps, useMediaQuery, useTheme, Divider } from '@mui/material';
import { getRecapsByDate } from '../util/RecapsService';
import Recap, { RecapInterface } from './Recap';

const markRecapAsSeen = (recapId: number, date: string) => {
  const seenRecaps = JSON.parse(localStorage.getItem(`newsSeen-${date}`) || '{}');
  seenRecaps[recapId] = true;
  localStorage.setItem(`newsSeen-${date}`, JSON.stringify(seenRecaps));
};

const checkIfRecapWasSeen = (recapId: number, date: string): boolean => {
  const seenRecaps = JSON.parse(localStorage.getItem(`newsSeen-${date}`) || '{}');
  return !!seenRecaps[recapId];
};

const RecapDialog = () => {
  const [recaps, setRecaps] = useState<RecapInterface[]>([]);
  const [date, setDate] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const newDate = new Date();
    const timeZoneOffset = newDate.getTimezoneOffset() * 60000;
    const localeDate = new Date(newDate.getTime() - timeZoneOffset);
    const isoDate = localeDate.toISOString().split('T')[0];
    setDate(isoDate);

    getRecapsByDate(date).then((res) => {
      if (res.length > 0) {
        setRecaps(res);
        const unseenRecaps = res.filter((recap: RecapInterface) => !checkIfRecapWasSeen(recap.id, date));
        if (unseenRecaps.length > 0) {
          setOpen(true);
          setScroll('paper');
          unseenRecaps.forEach((recap: RecapInterface) => markRecapAsSeen(recap.id, date));
        }
      } else {
        setOpen(false);
      }
    }).catch((err) => console.error(err));
  }, [date]);

  const handleClose = (event: {}, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  };

  if (recaps.length === 0) {
    return null;
  }
  
  return (
    <div className='MuiContainer-root MuiContainer-maxWidthMd css-xdmu94-MuiContainer-root'>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        fullScreen={fullScreen}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === 'paper'}>
          {Array.isArray(recaps) && recaps.map((recap: RecapInterface, index: number) => (
            <React.Fragment key={recap.id}>
                <Recap recap={recap} />
                {index !== recaps.length - 1 && <Divider sx={{ borderWidth: 2 }} />}
            </React.Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose({}, '')}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RecapDialog;