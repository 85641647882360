import React, { useMemo, useContext, useRef } from "react";
import Page from "../Components/Page";
import AOListPage, { AOListData } from "../Components/AOListPage";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import {
    PageCompanyListQuery,
    PageCompanyListQueryVariables,
} from "../graphql/query-types";
import useSearch from "../CustomHooks/useSearch";
import { CacheStatus, CacheStatusContext } from "../App";
import useSettings from "../features/settings/hooks/useSettings";

const query = loader("../graphql/PageCompanyList.graphql");

export default function CompanyList() {
    const congress = useSettings().congress;
    const cacheStatus = useContext(CacheStatusContext);
    const enablePage =
        cacheStatus === CacheStatus.READY ||
        cacheStatus === CacheStatus.SHOW_READY;

    const { error, data } = useQuery<
        PageCompanyListQuery,
        PageCompanyListQueryVariables
    >(query, {
        variables: { congress: congress as number },
        skip: !enablePage || congress === undefined,
    });

    const names = useMemo(() => {
        const names: AOListData[] = [];

        data?.congress?.companys
            ?.filter((a) => a !== undefined && a !== null)
            .sort(
                (a, b) =>
                    a?.company
                        ?.charAt(0)
                        .localeCompare(b?.company?.charAt(0) ?? "") ?? 0
            )
            .forEach((company) => {
                //Build the Name String
                if (company && company.company) {
                    const letter = company.company.charAt(0).toUpperCase();
                    if (
                        names.length === 0 ||
                        names[names.length - 1][0].localeCompare(letter) !== 0
                    )
                        names.push([letter, []]);

                    names[names.length - 1][1].push({
                        id: company.id,
                        text: company.company,
                    });
                }
            });

        names.forEach((letterList) => {
            letterList[1].sort((a, b) => a.text.localeCompare(b.text));
        });

        return names;
    }, [data]);

    const namesFlat = useMemo(() => {
        return names.map((a) => a[1]).flat();
    }, [names]);

    const fields = useRef(["text"]);
    const namesSearch = useSearch({
        data: namesFlat,
        fields: fields.current,
    });

    if (!enablePage) {
        return (
            <Page padding title="Institutes">
                <Typography>
                    This page is only available if the offline mode is enabled
                </Typography>
            </Page>
        );
    }

    if (error) return <Typography>Error Loading Data</Typography>;

    console.log(window.history.state);

    return (
        <Page title="Institutes">
            <AOListPage
                data={namesSearch.searched ? namesSearch.data : names}
                generateLinks={(item) => `/institutes/${item.id}`}
            />
        </Page>
    );
}
