import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { CacheStatus, CacheStatusContext } from "./App";
import useSettings from "./features/settings/hooks/useSettings";

function urlBase64ToUint8Array(base64String: string) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

interface NotificationsBaseContextInterface {
    isLoading: boolean;
    token: string | null;
    blocked: boolean;
    error: boolean;
    requestToken: () => Promise<string | null>;
}

export const NotificationsBaseContext =
    createContext<NotificationsBaseContextInterface | null>(null);

interface NotificationsBaseProviderProps {
    children: React.ReactNode;
}

const NotificationsBaseProvider = ({ children }: NotificationsBaseProviderProps) => {
    const settings = useSettings();
    const [token, setToken] = useState<string | null>(
        localStorage.getItem("token")
    );
    const [blocked, setBlocked] = useState(false);
    const [error, setError] = useState<string|null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEnableCache, setShowEnableCache] = useState(false);
    const cacheStatus = useContext(CacheStatusContext);

    const requestToken: () => Promise<string | null> = useCallback(async () => {
        const reg = await navigator.serviceWorker.getRegistration();
        if(!(cacheStatus === CacheStatus.READY || cacheStatus === CacheStatus.SHOW_READY)) {
            setShowEnableCache(true);
            return null;
        }

        let subscription = await reg?.pushManager.getSubscription();

        if (subscription === null) {
            subscription = await fetch(
                settings.dataServer + "/notifications/publickey"
            )
                .then((serverKey) => serverKey.text())
                .then((serverKey) => {
                    console.log({
                        serverKey,
                        applicationServerKey: urlBase64ToUint8Array(serverKey),
                        userVisibleOnly: true,
                    })
                    return reg?.pushManager.subscribe({
                        applicationServerKey: urlBase64ToUint8Array(serverKey),
                        userVisibleOnly: true,
                    });
                })
                .catch((err) => {
                    setError(err.message);
                    console.error(err);
                    return null;
                });
        }

        if (subscription !== null && subscription !== undefined) {
            if (token === null) {
                const response = await fetch(
                    settings.dataServer + "/notifications/register",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(subscription),
                    }
                ).catch((err) => {
                    setError("");
                    console.error(err);
                });

                if (response && response.status === 200) {
                    const tempToken = await response.text();
                    setToken(tempToken);
                    localStorage.setItem("token", tempToken);
                    setError(null);
                    return tempToken;
                } else {
                    setError("");
                    return null;
                }
            } else {
                const response = await fetch(
                    settings.dataServer + "/notifications/update",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                        },
                        body: JSON.stringify(subscription),
                    }
                ).catch((err) => {
                    setError("");
                    console.error(err);
                });

                if (response && response.status === 401) {
                    setToken(null);
                    localStorage.removeItem("token");
                    setError("");
                    console.error("Cannot update notification data");
                    return null;
                }

                if (response && response.status !== 200) {
                    setError("");
                    console.error("Cannot update notification data");
                    return null;
                }
                return token;
            }
        }

        return null;
    }, [settings.dataServer, token, cacheStatus]);

    useEffect(() => {
        navigator.serviceWorker
            .getRegistration()
            .then(async (reg) => {
                const permissionState = await reg?.pushManager.permissionState({
                    userVisibleOnly: true,
                });

                switch (permissionState) {
                    case "granted":
                        await requestToken();
                        break;
                    case "prompt":
                        break;
                    case "denied":
                        setBlocked(true);
                        break;
                    default:
                        setError("Unknown Permission Error");
                        break;
                }
                setIsLoading(false);
            })
            .catch(() => {
                setError("Error loading Permission");
                setIsLoading(false);
            });
    }, [requestToken]);

    return (
        <NotificationsBaseContext.Provider
            value={{
                blocked,
                error: (error !== null),
                requestToken,
                token,
                isLoading
            }}
        >
            <Dialog open={showEnableCache} onClose={() => setShowEnableCache(false)}>
                <DialogTitle>
                    Disabled offline support
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To enable notifications the offline support has to be enabled. Please enable it using the orange bar at the top.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowEnableCache(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
            {children}
        </NotificationsBaseContext.Provider>
    );
};

export default NotificationsBaseProvider;
