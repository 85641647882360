import { Typography, styled } from "@mui/material";
import Unique from "../../../../Components/unique/Unique";
import Symposium from "../../../../DataModels/Symposium";

const StyledRoot = styled("div")({
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",

    "& > *": {
        margin: "10px",
    },
});

interface Props {
    symposium: Symposium;
}

export default function SymposiumHeader(props: Props) {
    return (
        <StyledRoot>
            <Unique color={props.symposium.color ?? undefined}>
                {props.symposium.short ?? ""}
            </Unique>
            <Typography variant="h5" noWrap>
                {props.symposium.title}
            </Typography>
        </StyledRoot>
    );
}
