import { useCallback, useState, useEffect, useRef } from "react";

export default function useBoundingRect() {
    const [node, setNode] = useState<HTMLElement>();
    const [dirty, setDirty] = useState(true);

    useEffect(() => {
        if (node) {
            const playInMud = () => setDirty(true);
            window.addEventListener("resize", playInMud);
            const ro = new ResizeObserver(playInMud);

            ro.observe(node);
            setTimeout(playInMud, 1000);

            return () => {
                window.removeEventListener("resize", playInMud);
                ro.disconnect();
            };
        }
    }, [node]);

    const rect = useRef(node?.getBoundingClientRect());

    const ref = useCallback(
        (nodeParam: HTMLElement | null) => {
            if (dirty && nodeParam) {
                setNode(nodeParam);
                rect.current = nodeParam.getBoundingClientRect();
                setDirty(false);
            }
        },
        [dirty]
    );

    return {
        ref,
        rect: rect.current,
    };
}
