const tf = (strings: TemplateStringsArray, ...times: (Date|number|string)[]) => {
    let result = "";

    for (let i = 0; i < strings.length; i++) {

        result += strings[i];

        if (times.length > i) {
            const time = times[i];

            if (typeof time === "string") {
                result += time;
            } else {
                const date = (typeof time === "number") ? (
                    new Date(time * 1000)
                ) : time;

                switch (result.charAt(result.length - 1)) {
                    case "t":
                        result = result.slice(0, -1) + date.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            timeZone: "Europe/Berlin"
                        });
                        break;
                    case "d":
                        result = result.slice(0, -1) + date.toLocaleDateString([], {
                            timeZone: "Europe/Berlin"
                        });
                        break;
                    default:
                        throw new Error("TimeFormatter needs t or d before time")
                }
            }
        }
    }

    return result;
};

export default tf;
