import React, { Dispatch, SetStateAction } from "react";
import useLocalStorage from "../../../CustomHooks/useLocalStorage";

export const FavoriteContext = React.createContext<[number[], Dispatch<SetStateAction<number[]>>]|null>(null);

interface Props {
    children: React.ReactNode;
}

export function FavoriteContextProvider({children}: Props) {
    const favoriteState = useLocalStorage<number[]>("favorites", []);

    return (
        <FavoriteContext.Provider value={favoriteState}>
            {children}
        </FavoriteContext.Provider>
    );
}