import { useQuery } from "@apollo/client";
import { styled, Typography } from "@mui/material";
import { loader } from "graphql.macro";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../../Components/Loading";
import TimeDisplay from "../../../Components/TimeDisplay";
import Symposium from "../../../DataModels/Symposium";
import {
    PageTimeslotSessionDetailsQuery,
    PageTimeslotSessionDetailsQueryVariables,
} from "../../../graphql/query-types";
import Timeslot from "../../../DataModels/Timeslot";
import SymposiumHeader from "../../../features/scientificProgram/components/symposium/SymposiumHeader";
import SessionList from "../../../features/scientificProgram/components/session/SessionList";

const StyledTimes = styled("div")({
    marginLeft: "10px",
});

interface TimeslotSessionDetailsParams {
    id: string;
    timeslot: string;
}

const query = loader("../../../graphql/PageTimeslotSessionDetails.graphql");

export default function TimeslotSessionDetails() {
    const params = useParams<TimeslotSessionDetailsParams>();
    const location = useLocation();

    const symposiumId = parseInt(params.id);
    const timeslotId = parseInt(params.timeslot);

    const { loading, error, data } = useQuery<
        PageTimeslotSessionDetailsQuery,
        PageTimeslotSessionDetailsQueryVariables
    >(query, {
        variables: { id: timeslotId },
    });

    if (loading) return <Loading />;
    if (error || !data) return <Typography>Error Loading data</Typography>;

    //Get the right Symposium from the Symposiums in the Timeslot
    let symposium = (data.timeslot?.symposiums as Symposium[]).filter(
        (symp: Symposium) => {
            return symp.id === symposiumId;
        }
    )[0];

    if (!symposium) {
        return <Typography>Error</Typography>;
    }

    const sessionsJoined = [
        ...data?.timeslot?.hd_sessions?.map((a: any) => {
            return { ...a, type: "hdsession" };
        })!,
        ...data?.timeslot?.poster_times?.map((a: any) => {
            return { ...a, type: "postertime" };
        })!,
    ];

    const sessions = sessionsJoined
        .map((hd) => {
            let result = {
                ...hd.session,
                hdID: hd.id,
                type: hd.type,
            };
            return result;
        })
        .filter((session) => {
            return session.symposium.id === symposiumId;
        })
        .sort((a, b) => {
            let [a1, a2] = a.session.split(".");
            let [b1, b2] = b.session.split(".");

            let ia1 = parseInt(a1);
            let ia2 = parseInt(a2);
            let ib1 = parseInt(b1);
            let ib2 = parseInt(b2);

            if (ia1 !== ib1) return ia1 - ib1;
            else return ia2 - ib2;
        });

    return (
        <div>
            <SymposiumHeader symposium={symposium} />
            <StyledTimes>
                <TimeDisplay timeslot={data.timeslot as Timeslot} />
            </StyledTimes>
            <SessionList
                sessions={sessions}
                color={symposium.color || null}
                links={(session: any) => {
                    return (
                        location.pathname +
                        "/" +
                        session.type +
                        "/" +
                        session.hdID
                    );
                }}
            />
        </div>
    );
}
