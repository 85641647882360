import React, { useState, useRef, useCallback, useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import PSDay from "./PSDay";
import Paper from "../../../DataModels/Paper";
import Event from "../../../DataModels/Event";
import Timeslot from "../../../DataModels/Timeslot";
import { IconButton, Typography, Tabs, Tab, styled } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import tf from "../../../shared/timeFormat";
import Alert from "@mui/material/Alert";
import useFavorites from "../../personalSchedule/hooks/useFavorites";
import useSettings from "../../settings/hooks/useSettings";

const testQuery = gql`
    query TestQuery($ids: [Int], $congress: Int) {
        papers(ids: $ids) {
            id
            title
            abstract
            unique
            status
            color

            times {
                begin
                end
            }
            rooms {
                id
                room
            }
        }

        congress(id: $congress) {
            id
            timeslots {
                id
                begin
                end
                desc_id
                desc
            }
        }
    }
`;

const StyledWrapper = styled("div")({
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "100%",
    overflowX: "scroll",
    scrollSnapType: "x mandatory",

    "& > *": {
        scrollSnapAlign: "end",
        scrollSnapStop: "always",
    },
});

const StyledButtons = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const StyledTab = styled(Tab)({
    minWidth: 0,
    minHeight: 0,
});

const StyledTabs = styled(Tabs)({
    minHeight: 0,
});

const StyledTabWrapper = styled("div")({
    marginBottom: 16,
});
interface Props {
    favorites?: number[];
    print?: boolean;
}

export default function PSTimeTable(props: Props) {
    const settings = useSettings();
    const [width, setWidth] = useState(0);
    const scrollDivRef = useRef<HTMLDivElement | null>(null);
    const scrollDivCallback = useCallback((node: HTMLDivElement) => {
        scrollDivRef.current = node;

        if (node) {
            setWidth(node.clientWidth);
        }
    }, []);
    const [selectedDay, setSelectedDay] = useState(0);
    const favorites = useFavorites();

    const { data: paperData } = useQuery(testQuery, {
        variables: {
            ids: props.favorites ?? favorites,
            congress: settings.congress,
        },
    });

    let days = useMemo(() => {
        let days: Timeslot[][] = [];
        let timeOffset = 0;
        const dayStrings: string[] = [];
        paperData?.congress.timeslots
            .slice()
            .sort((a: any, b: any) => a.begin - b.begin)
            .forEach((timeslot: Timeslot) => {
                if (timeslot.begin) {
                    if (timeOffset === 0) timeOffset = timeslot.begin;
                    const date = new Date(timeslot.begin * 1000);

                    let zeroDate = new Date(
                        (timeslot.begin - timeOffset) * 1000
                    );
                    let dayIndex = zeroDate.getUTCDate() - 1;

                    if (!days[dayIndex]) {
                        days[dayIndex] = [];
                        dayStrings.push(
                            date.toLocaleDateString([], {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                timeZone: "Europe/Berlin",
                            })
                        );
                    }
                    days[dayIndex].push(timeslot);
                }
            });
        return days;
    }, [paperData?.congress.timeslots]);

    if (!paperData) return null;

    let events: Event[] = [];
    paperData?.papers.forEach((paper: Paper) => {
        paper.times?.forEach((time, i) => {
            if (paper.rooms && paper.rooms[i]) {
                events.push({
                    paper: paper,
                    begin: time.begin ?? 0,
                    end: time.end ?? 0,
                    room: paper.rooms[i],
                });
            }
        });
    });

    let lastEndTime = 0;
    let overlap = events
        .sort((a, b) => a.begin - b.begin)
        .some((value) => {
            const isPoster = value.end - value.begin >= 3600;
            if (lastEndTime > (value.begin ?? 0) && !isPoster) {
                return true;
            }

            lastEndTime = Math.max(lastEndTime, value.end ?? 0);
            return false;
        });

    const scrollListener = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const scroll = event.currentTarget.scrollLeft;

        if (scrollDivRef.current) {
            const width = scrollDivRef.current.clientWidth;
            setSelectedDay(Math.round(scroll / width));
        }
    };

    const tabChangeHandler = (_: React.ChangeEvent<{}>, value: any) => {
        scrollDivRef.current?.scrollTo({
            left: value * width,
            behavior: "smooth",
        });
    };

    const dateText = tf`d${new Date((days[selectedDay][0].begin ?? 0) * 1000)}`;

    const dateOptions: Intl.DateTimeFormatOptions = {
        weekday: "short",
        timeZone: "Europe/Berlin",
    };

    return (
        <div>
            {overlap && !props.print && (
                <Alert variant="standard" severity="warning">
                    You have overlapping events in your schedule
                </Alert>
            )}
            {!props.print && (
                <div
                    style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 200,
                        backgroundColor: "white",
                    }}
                >
                    <StyledButtons>
                        <IconButton
                            onClick={() =>
                                scrollDivRef.current?.scrollBy({
                                    left: -width,
                                    behavior: "smooth",
                                })
                            }
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography>{dateText}</Typography>
                        <IconButton
                            onClick={() =>
                                scrollDivRef.current?.scrollBy({
                                    left: width,
                                    behavior: "smooth",
                                })
                            }
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </StyledButtons>
                    <StyledTabWrapper>
                        <StyledTabs
                            variant="fullWidth"
                            value={selectedDay}
                            onChange={tabChangeHandler}
                        >
                            {days.map((timeslots, i) => {
                                if (timeslots[0] && timeslots[0].begin) {
                                    const date = new Date(
                                        timeslots[0].begin * 1000
                                    );
                                    const dateStr = date.toLocaleDateString(
                                        ["en-US"],
                                        dateOptions
                                    );
                                    return (
                                        <StyledTab
                                            label={dateStr}
                                            value={i}
                                            key={i}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </StyledTabs>
                    </StyledTabWrapper>
                </div>
            )}
            {!props.print ? (
                <StyledWrapper
                    className={"hideScrollbar"}
                    onScroll={scrollListener}
                    ref={scrollDivCallback}
                >
                    {days.map((timeslots, i) => {
                        return (
                            <PSDay
                                events={events}
                                timeslots={timeslots}
                                key={i}
                                debug={i}
                            />
                        );
                    })}
                </StyledWrapper>
            ) : (
                <>
                    <p>09:30 - 11:00 Scientific Sessions</p>
                    <p>11:00 - 11:30 Break</p>
                    <p>11:30 - 13:00 Scientific Sessions</p>
                    <p>13:00 - 14:30 Lunch</p>
                    <p>14:30 - 16:00 Latest Results</p>
                    {days.map((timeslots, i) => {
                        return (
                            <PSDay
                                events={events}
                                timeslots={timeslots}
                                key={i}
                                debug={i}
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
}
