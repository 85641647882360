import React, { useMemo } from "react";
import PresentationListTimeslot from "./PresentationListTimeslot";
import PresentationListEvent from "./PresentationListEvent";
import HDSession from "../../../../DataModels/HDSession";
import Timeslot from "../../../../DataModels/Timeslot";
import PosterTime from "../../../../DataModels/PosterTime";
import { PaperTime } from "../../../../DataModels/Paper";

interface PresentationListProps {
    color: string;
    dateWidth: string;
    stick: number;
    links: Function;
    hd_sessions?: HDSession[];
    breaks?: Timeslot[];
    poster_times?: PosterTime[];
}

interface PosterTimeConn {
    pt: PosterTime;
    extra_ts: Timeslot[];
}

export default function PresentationList(props: PresentationListProps) {
    const breaks = useMemo(() => {
        return props.breaks?.sort((a, b) => (a?.begin ?? 0) - (b?.begin ?? 0));
    }, [props.breaks]);

    const hd_sessions = useMemo(() => {
        return props.hd_sessions
            ?.slice()
            .sort(
                (a, b) => (a.timeslot?.begin ?? 0) - (b.timeslot?.begin ?? 0)
            );
    }, [props.hd_sessions]);

    const poster_times = useMemo(() => {
        return props.poster_times
            ?.slice()
            .sort((a, b) => (a.timeslot?.begin ?? 0) - (b.timeslot?.end ?? 0))
            .reduce<PosterTimeConn[]>((prev, cur) => {
                const i = prev.length - 1;
                if (cur.papers && cur.papers.length > 0) {
                    if (
                        prev.length > 0 &&
                        prev[i].pt.papers![0].id === cur.papers[0].id
                    ) {
                        if (cur.timeslot) {
                            prev[i].extra_ts.push(cur.timeslot);
                        }
                    } else {
                        prev.push({
                            pt: cur,
                            extra_ts: [],
                        });
                    }
                }
                return prev;
            }, []);
    }, [props.poster_times]);

    let nextBreak = 0;
    return (
        <div>
            {hd_sessions?.map((hds, i2) => {
                let had_break: Timeslot | null = null;
                while (
                    breaks &&
                    breaks[nextBreak] &&
                    (breaks[nextBreak].begin ?? 0) < (hds.timeslot?.begin ?? 0)
                ) {
                    const b = breaks[nextBreak];
                    if (
                        b.begin &&
                        props.hd_sessions![i2 - 1] &&
                        props.hd_sessions![i2 - 1].timeslot &&
                        hds.timeslot &&
                        b.begin === props.hd_sessions![i2 - 1].timeslot?.end &&
                        b.end === hds.timeslot.begin
                    ) {
                        had_break = breaks[nextBreak];
                    }
                    nextBreak++;
                }
                return (
                    <PresentationListTimeslot
                        key={hds.id}
                        hdspt={hds}
                        stickTop={props.stick}
                        color={props.color}
                        last_break={had_break}
                    >
                        {hds.papers?.map((paper) => {
                            const timeIndex =
                                paper.times?.findIndex(findTime(hds)) ?? -1;

                            return (
                                <PresentationListEvent
                                    key={paper.id}
                                    color={props.color}
                                    paper={paper}
                                    timeIndex={timeIndex}
                                    link={props.links(paper)}
                                />
                            );
                        })}
                    </PresentationListTimeslot>
                );
            })}
            {poster_times?.map((ptc) => {
                return (
                    <PresentationListTimeslot
                        key={ptc.pt.id}
                        hdspt={ptc.pt}
                        stickTop={props.stick}
                        color={props.color}
                        extra_timeslots={ptc.extra_ts}
                    >
                        {ptc.pt.papers?.map((paper) => {
                            const timeIndex =
                                paper.times?.findIndex(findTime(ptc.pt)) ?? -1;

                            return (
                                <PresentationListEvent
                                    key={paper.id}
                                    color={props.color}
                                    paper={paper}
                                    timeIndex={timeIndex}
                                    link={props.links(paper)}
                                />
                            );
                        })}
                    </PresentationListTimeslot>
                );
            })}
        </div>
    );
}

function findTime(
    hdspt: HDSession | PosterTime
): (value: PaperTime, index: number, obj: PaperTime[]) => boolean {
    return (t) =>
        (hdspt.timeslot?.begin ?? Number.MAX_VALUE) <=
            (t.begin ?? Number.MIN_VALUE) &&
        (hdspt.timeslot?.end ?? Number.MIN_VALUE) >=
            (t.end ?? Number.MAX_VALUE);
}
