import PrintIcon from '@mui/icons-material/Print';
import { IconButton, styled, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import Unique from '../../../../Components/unique/Unique';
import Session from '../../../../DataModels/Session';

const StyledHeaderTitle = styled("div")({
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",

    "& > *": {
        margin: "10px",
    },
});
const StyledUnique = styled(Unique)({
    flex: "0 0 auto",
});
const StyledTitle = styled(Typography)({
    flexGrow: 1
});
interface Props {
    color?: string;
    session?: Session;
    printRef?: React.MutableRefObject<null>;
}

export default function SessionHeader(props: Props) {
    const handlePrint = useReactToPrint({
        content: () => props.printRef?.current ?? null
    });
    
    return (
        <StyledHeaderTitle>
            <StyledUnique color={props.color}>
                {(props.session?.symposium?.short ?? "") +
                    (props.session?.session ?? "")}
            </StyledUnique>
            <StyledTitle variant="h5" noWrap>
                {props.session?.name ?? ""}
            </StyledTitle>
            {props.printRef && (
                <IconButton onClick={handlePrint} className="no-print">
                    <PrintIcon />
                </IconButton>
            )}
        </StyledHeaderTitle>
    );
}
