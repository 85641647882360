import { useEffect, useState } from "react";
import { commonSettings } from "../../../settings";
import { CongressSettings } from "../context/SettingsContext";

export default function useCongressSettings() {
    const [settings, setSettings] = useState<CongressSettings[]|null>(null);
    const [error, setError] = useState<boolean|null>(null);

    useEffect(() => {
        const stored = localStorage.getItem("settings");
        if (stored) {
            setSettings(JSON.parse(stored));
            setError(false);
        }

        fetch(commonSettings.dataServer + "/settings").then((res) => {
            return res.json();
        }).then(res => {
            setSettings(res);
            localStorage.setItem("settings", JSON.stringify(res));
        }).catch(err => {
            setError(v => v === null);
            console.error(err);
        });
    }, []);

    return {
        settings,
        error: !!error
    };
}