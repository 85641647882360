import { styled, Typography } from "@mui/material";
import { useMemo, useRef } from "react";
//import renderHTML from 'react-render-html';
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useParams } from "react-router-dom";
import { BreadcrumbItem } from "../../../Components/Breadcrumb";
import Loading from "../../../Components/Loading";
import Page from "../../../Components/Page";
import SessionDescription from "../../../Components/SessionDescription";
import { useBreadcrumbs } from "../../../CustomHooks/useBreadcrumbs";
import Paper from "../../../DataModels/Paper";
import Session from "../../../DataModels/Session";
import Timeslot from "../../../DataModels/Timeslot";
import {
    PageSessionQuery,
    PageSessionQueryVariables,
} from "../../../graphql/query-types";
import useSettings from "../../settings/hooks/useSettings";
import PresentationList from "../components/presentation/PresentationList";
import SessionHeader from "../components/session/SessionHeader";
import SessionJoinChatButton from "../components/session/SessionJoinChatButton";

interface PaperParams {
    id: string;
}

const query = loader("../../../graphql/PageSessionQuery.graphql");

function generateLink(paper: Paper) {
    return "/browser/presentation/" + paper.id;
}

const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
});

export default function PageSession() {
    const pageRef = useRef(null);

    let id = parseInt(useParams<PaperParams>().id);
    const settings = useSettings();

    const { loading, error, data } = useQuery<
        PageSessionQuery,
        PageSessionQueryVariables
    >(query, {
        variables: { id, cid: settings.congress! },
        skip: !id || !settings.congress,
        //returnPartialData: true,
    });

    const breadcrumbs = useMemo<BreadcrumbItem[]>(
        () => [
            {
                name: "Scientific Program",
                link: "/browser",
            },
            {
                name: data?.session?.symposium?.short ?? "",
                link: `/browser/symposium/${data?.session?.symposium?.id}`,
            },
            {
                name: data?.session?.session ?? "",
                link: `/browser/session/${id}`,
            },
        ],
        [data?.session, id]
    );
    useBreadcrumbs(breadcrumbs);

    const timeslots: Timeslot[] = useMemo(() => {
        if (data?.congress?.timeslots) {
            return data.congress.timeslots.flatMap((ts) =>
                ts && ts.desc_id === 2 ? [ts] : []
            );
        }

        return [];
    }, [data?.congress?.timeslots]);

    if (loading) return <Loading />;
    if (error || !data || Object.keys(data).length <= 0)
        return <Typography>Error Loading Data</Typography>;

    let session = data.session;
    let symposium = session?.symposium ?? undefined;

    return (
        <Page title="Scientific Program" padding ref={pageRef}>
            <StyledRoot>
                <div>
                    <SessionHeader
                        session={session as Session}
                        color={symposium?.color ?? undefined}
                        printRef={pageRef}
                    />
                    <SessionDescription session={session as Session} />
                    <br></br>
                </div>
                {data.session?.chat && (
                    <SessionJoinChatButton roomId={session?.chat ?? ""} />
                )}
                <PresentationList
                    stick={0}
                    color={symposium?.color || "#333333"}
                    dateWidth="100vw"
                    links={generateLink}
                    hd_sessions={data.session?.hd_sessions ?? []}
                    breaks={timeslots}
                    poster_times={data.session?.poster_times ?? []}
                />
            </StyledRoot>
        </Page>
    );
}
