import { Fragment } from "react";
import { Typography, styled } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RoomIcon from "@mui/icons-material/Room";
import Timeslot from "../DataModels/Timeslot";
import Room from "../DataModels/Room";
import { PaperTime } from "../DataModels/Paper";
import tf from "../shared/timeFormat";

const StyledRow = styled("div")({
    display: "flex",
    alignItems: "center",
    "& > *": {
        margin: "0.2em",
    },
});
interface Props {
    timeslot: Timeslot | PaperTime;
    room?: Room;
}

//Props: timeslot, room
export default function TimeDisplay(props: Props) {
    let time: string | undefined = undefined;
    let date: string | undefined = undefined;

    if (props.timeslot.begin && props.timeslot.end) {
        let begin = new Date(props.timeslot.begin * 1000);
        let end = new Date(props.timeslot.end * 1000);

        date = begin?.toLocaleDateString("en-US", {
            weekday: "short",
            day: "numeric",
            month: "long",
            year: "numeric",
            timeZone: "Europe/Berlin",
        });

        time = tf`t${begin} - t${end}`;
    }

    return (
        <Fragment>
            <StyledRow>
                <CalendarTodayIcon />
                <Typography>{date}</Typography>
            </StyledRow>
            <StyledRow>
                <AccessTimeIcon />
                <Typography>{time}</Typography>
            </StyledRow>
            {props.room && (
                <StyledRow>
                    <RoomIcon />
                    {/*<StyledLink to={`/map?room=${props.room.id}`}>*/}
                    <Typography>{props.room.room}</Typography>
                    {/*</StyledLink>*/}
                </StyledRow>
            )}
        </Fragment>
    );
}
