import { useMemo } from "react";
import { List, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { BreadcrumbItem } from "../../../Components/Breadcrumb";
import Loading from "../../../Components/Loading";
import { useBreadcrumbs } from "../../../CustomHooks/useBreadcrumbs";
import Symposium from "../../../DataModels/Symposium";
import {
    PageCongressQuery,
    PageCongressQueryVariables,
} from "../../../graphql/query-types";
import useSettings from "../../settings/hooks/useSettings";
import SymposiumList from "../components/symposium/SymposiumList";
import Page from "../../../Components/Page";

const query = loader("../../../graphql/PageCongress.graphql");

export default function Congress() {
    const settings = useSettings();
    const id = settings.congress;
    const { loading, error, data } = useQuery<
        PageCongressQuery,
        PageCongressQueryVariables
    >(query, {
        variables: { id: id as number },
        skip: id === undefined,
    });

    const breadcrumbs = useMemo<BreadcrumbItem[]>(
        () => [
            {
                name: "Scientific Program",
                link: "/browser",
            },
        ],
        []
    );
    useBreadcrumbs(breadcrumbs);

    if (loading) return <Loading />;
    if (error || !data) return <Typography>Error Loading Data</Typography>;

    return (
        <Page padding title="Scientific Program">
            <List>
                <SymposiumList
                    symposiums={data.congress?.symposiums as Symposium[]}
                />
            </List>
        </Page>
    );
}
