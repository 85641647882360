import React, {
    ReactNode,
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
} from "react";
import { NavBarOptionsContext } from "./Navbar";
import { Container, styled, Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import useMenu from "../CustomHooks/useMenu";

interface Props {
    children: ReactNode;
    title?: string;
    className?: string;
    fullSize?: boolean;
    padding?: boolean;
    noContainer?: boolean;
    print?: boolean;
}

const StyledWrapper = styled("div")({
    position: "relative",
});
const StyledSidePadding = styled("div")({
    padding: "0 20px",
});

const Page = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [, setTitle] = useContext(NavBarOptionsContext).title;
    const [, setToAdd] = useContext(NavBarOptionsContext).toAdd;
    const [, setSearch] = useContext(NavBarOptionsContext).search;

    useLayoutEffect(() => {
        setTitle(props.title ?? "");
    }, [setTitle, props.title]);

    useLayoutEffect(() => {
        setToAdd(undefined);
        setSearch(undefined);
    }, [setToAdd, setSearch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    useMenu("Print", handlePrint, !!props.print);

    return (
        <Box sx={{ paddingTop: props.padding ? 2 : 0 }} ref={ref}>
            <div ref={printRef}>
                {props.noContainer ? (
                    <StyledSidePadding>{props.children}</StyledSidePadding>
                ) : (
                    <Container maxWidth="md">
                        <StyledWrapper>{props.children}</StyledWrapper>
                    </Container>
                )}
            </div>
        </Box>
    );
});

export default Page;
