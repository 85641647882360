import { Breadcrumbs, styled } from "@mui/material";
import BreadLink from "./BreadLink";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useContext } from "react";
import { NavBarOptionsContext } from "../Components/Navbar";

const StyledRoot = styled("div")({
    position: "relative",
    backgroundColor: "#ddd",
    zIndex: 1300,
    padding: ".5em 2em",
});

export interface BreadcrumbItem {
    name: string;
    link: string;
}

export default function Breadcumb() {
    const [links] = useContext(NavBarOptionsContext).breadcrumbs;

    if (!links || links.length === 0) {
        return null;
    }

    return (
        <StyledRoot>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                {links?.map((link) => (
                    <BreadLink {...link} key={link.link} />
                ))}
            </Breadcrumbs>
        </StyledRoot>
    );
}
