import CloseIcon from "@mui/icons-material/Close";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Button, Card, IconButton, styled, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import IconText from "../Components/IconText";
import Page from "../Components/Page";
import PagePresentationDetails from "../features/scientificProgram/pages/PagePresentationDetails";
import PageSymposium from "../features/scientificProgram/pages/PageSymposium";
import useSettings from "../features/settings/hooks/useSettings";
import PersonalSchedule from "../features/personalSchedule/pages/PersonalSchedule";

const StyledField = styled("div")({
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    position: "fixed",
    bottom: 0,
    zIndex: 9999,
    backgroundColor: "#fff",
    padding: "1rem",
    borderTopLeftRadius: "1em",
    borderTopRightRadius: "1em",
    left: 0,
    right: 0,
    maxWidth: "1280px",
    margin: "0 auto",
});

const StyledStatus = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const StyledHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "space-between",
});

const StyledText = styled("span")({
    marginBottom: "1rem",
});

const StyledHighlight = styled("div")({
    position: "fixed",
    border: "3px solid red",
    boxShadow: "0 0 0 100vmax #0009",
    zIndex: 9999,
});

const StyledMenuCard = styled(Card)({
    padding: "1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1em",
});

type HowToEntries = {
    [key: string]: HowToEntry;
};

type HowToRects = {
    [key: string]: DOMRect;
};

interface HowToEntry {
    title: string;
    steps: HowToStep[];
}

interface HowToStep {
    component: JSX.Element;
    content: string | JSX.Element | (string | JSX.Element)[];
    howToId: string;
}

export const HowToContext = React.createContext<
    [HowToRects, Dispatch<SetStateAction<HowToRects>>] | null
>(null);

export default function HowTo() {
    // Block the scrolling
    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    });

    const settings = useSettings();

    const elem1 = (
        <PagePresentationDetails paperId={settings.examplePaper ?? 0} />
    );
    const elem2 = <PersonalSchedule favorites={[settings.examplePaper ?? 0]} />;
    const elem3 = (
        <PageSymposium symposiumId={settings.exampleSymposium ?? 0} />
    );
    const elem4 = <PersonalSchedule tab={1} />;
    const elem5 = (
        <PersonalSchedule
            tab={1}
            shareQRCode="<Here would be the resulting link>"
        />
    );
    const entries: HowToEntries = {
        schedule: {
            title: "How to manage your schedule",
            steps: [
                {
                    component: elem1,
                    content:
                        "You can add events to your personal schedule by marking them as favorite.",
                    howToId: "pd-favorite",
                },
                {
                    component: elem2,
                    content:
                        "The event then appears in your personal schedule.",
                    howToId: "pse-root",
                },
                {
                    component: elem3,
                    content: "You can also favorise a whole session at once.",
                    howToId: "session-list-fav",
                },
            ],
        },
        notifications: {
            title: "How to use notifications",
            steps: [
                {
                    component: elem2,
                    content: [
                        "Use the bell to enable notifications",
                        <IconText
                            icon={<NotificationsIcon />}
                            text="Notifcation is activated"
                        />,
                        <IconText
                            icon={<NotificationsNoneIcon />}
                            text="Notifcation is deactivated"
                        />,
                        <IconText
                            icon={<NotificationImportantIcon />}
                            text="Something went wrong"
                        />,
                    ],
                    howToId: "psevent-notification",
                },
                {
                    component: elem2,
                    content:
                        "In case that the notification bell shows an error, check if the webpage has the needed permission from the browser to display notifications (In Firefox click the settings icon at the left of the addressbar. In chrome its the lock symbol). If you have selected to clear all browsing data when closing the browser notifications won't work either.",
                    howToId: "psevent-notification",
                },
            ],
        },
        sharedata: {
            title: "How to share your data",
            steps: [
                {
                    component: elem4,
                    content:
                        "Navigate to the personal schedule and go to the share tab. Here you can select which data you want to share.",
                    howToId: "share-select",
                },
                {
                    component: elem4,
                    content:
                        'When you click on the "Generate Link" button your data will be uploaded and you will get a link.',
                    howToId: "share-gen-link",
                },
                {
                    component: elem5,
                    content:
                        "Now you have to open this link on the other device.",
                    howToId: "share-qrcode",
                },
            ],
        },
    };
    const [howToStep, setHowToStep] = useState<number>(0);
    const [howToName, setHowToName] = useState("");
    const rects = useState<HowToRects>({});
    const highlightrect =
        entries[howToName] &&
        entries[howToName].steps[howToStep] &&
        rects[0][entries[howToName].steps[howToStep].howToId];

    useEffect(() => {
        setHowToStep(0);
    }, [howToName]);

    if (!howToName) {
        return (
            <Page title="How To" padding>
                {Object.keys(entries).map((key) => (
                    <StyledMenuCard>
                        <Typography variant="h6">
                            {entries[key].title}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setHowToName(key)}
                        >
                            Start
                        </Button>
                    </StyledMenuCard>
                ))}
            </Page>
        );
    }

    const prev = () => {
        if (howToStep > 0) {
            setHowToStep((a) => (a as number) - 1);
        }
    };

    const next = () => {
        if (entries[howToName].steps.length - 1 > howToStep) {
            setHowToStep((a) => (a as number) + 1);
        }
    };

    const target = document.getElementById("modal-root");

    if (!target) {
        return null;
    }

    return ReactDOM.createPortal(
        <>
            <HowToContext.Provider value={rects}>
                {entries[howToName].steps[howToStep].component}
            </HowToContext.Provider>
            <div style={{ position: "fixed", inset: 0, zIndex: 3000 }} />
            <StyledHighlight
                sx={{
                    display: highlightrect ? "block" : "none",
                    top: highlightrect?.top,
                    left: highlightrect?.left,
                    width: highlightrect?.width,
                    height: highlightrect?.height,
                }}
            />
            <div>
                <StyledField>
                    <StyledHeader>
                        <Typography variant="h6">
                            {entries[howToName].title}
                        </Typography>
                        <IconButton>
                            <CloseIcon onClick={() => setHowToName("")} />
                        </IconButton>
                    </StyledHeader>
                    <StyledText>
                        {entries[howToName].steps[howToStep].content}
                    </StyledText>
                    <StyledStatus>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={prev}
                            disabled={howToStep === 0}
                        >
                            Prev
                        </Button>
                        <span>
                            {howToStep + 1} / {entries[howToName].steps.length}
                        </span>
                        {howToStep === entries[howToName].steps.length - 1 ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setHowToName("")}
                            >
                                Finish
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={next}
                            >
                                Next
                            </Button>
                        )}
                    </StyledStatus>
                </StyledField>
            </div>
        </>,
        target
    );
}
