import { Dialog, DialogTitle, DialogContent, Box, Divider, Chip, List, ListItem, ListItemIcon, ListItemText, Typography, DialogActions, Button } from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import Paper from "../../../../DataModels/Paper";
import User from "../../../../DataModels/User";
import createAuthorName from "../../../../shared/createAuthorName";

interface Props {
    open: boolean;
    paper: Paper;
    onClose?: () => unknown;
}

export default function AffiliationDialog(props: Props) {
    const build_author_layout = (authorsFunction: string, author: User) => (
        <Box>
            <Divider textAlign="left"><Chip label={<span><b>{authorsFunction}:</b> {createAuthorName(author)}</span>}/></Divider>
            {author.affiliations && author.affiliations.length > 0 && (
                <List dense>
                    {author.affiliations.map(company => (
                        <ListItem>
                            <ListItemIcon><BusinessIcon /></ListItemIcon>
                            <ListItemText>{company.company}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    )

    return (
        <Dialog open={props.open} fullWidth onClose={props.onClose}>
            <DialogTitle>Affiliations</DialogTitle>
            <DialogContent>
                {props.paper.author && build_author_layout("Author", props.paper.author)}
                {props.paper.presenting_author && build_author_layout("Presenting Author", props.paper.presenting_author)}
                {props.paper.co_authors && props.paper.co_authors.map(co_author => props.paper.presenting_author?.id !== co_author.id && build_author_layout("Co Author", co_author))}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
