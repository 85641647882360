import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Button, styled, Typography } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import { stripRoomId } from '../../../../shared/stripRoomId';

const StyledButton = styled(Button)<LinkProps>({
    display: "flex",
    gap: 16,
    color: "inherit",
    padding: 16,
    justifyContent: "start"
});

interface Props {
    roomId: string;
}

export default function SessionJoinChatButton({roomId}: Props) {
    const striped = stripRoomId(roomId);
    return <StyledButton component={Link} to={striped ? `/chat/${striped}` : ""}>
        <ChatBubbleOutlineIcon fontSize='large' />
        <Typography>Join Chat</Typography>
    </StyledButton>;
}
