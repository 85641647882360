import { useMemo } from "react";
import PSEventContainer from "./PSEventContainer";
import Timeslot from "../../../DataModels/Timeslot";
import Event from "../../../DataModels/Event";
import { styled } from "@mui/material";
import PSPosterContainer from "./PSPosterContainer";
import PSTimeslotWrapper from "./PSTimeslotWrapper";

const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    margin: "1rem 0",
});

interface Props {
    classes?: any;
    events: Event[];
    timeslots: Timeslot[];
    debug: number;
}

interface TimeslotEvents {
    timeslot: Timeslot;
    events: Event[];
}

export default function PSDay(props: Props) {
    //Sort by start Time
    props.timeslots.sort((a, b) => {
        return (a.begin ?? 0) - (b.begin ?? 0);
    });

    let timeslotEvents = useMemo(() => {
        //Assign Events to Timeslot
        let timeslotEvents: TimeslotEvents[] = [];
        //Query every Timeslot
        props.timeslots.forEach((timeslot) => {
            //Filter Events, which are in this Timeslot
            let filteredEvents = props.events.filter((event, i) => {
                return (
                    event.begin >= (timeslot.begin ?? 0) &&
                    event.end <= (timeslot.end ?? 0)
                );
            });

            //Add to Array
            timeslotEvents.push({
                timeslot,
                events: filteredEvents,
            });
        });

        return timeslotEvents;
    }, [props.timeslots, props.events]);

    return (
        <StyledRoot>
            {timeslotEvents.map((timeslotEvent, i) => {
                let child = null;
                if (timeslotEvent.timeslot.desc_id) {
                    switch (timeslotEvent.timeslot.desc_id) {
                        case 1:
                            child = (
                                <PSEventContainer
                                    events={timeslotEvent.events}
                                    hourHeight={400}
                                    key={i}
                                    extraRows={[
                                        timeslotEvent.timeslot.begin ?? 0,
                                        timeslotEvent.timeslot.end ?? 0,
                                    ]}
                                />
                            );
                            break;
                        case 4:
                            child = (
                                <PSPosterContainer
                                    events={timeslotEvent.events}
                                    hourHeight={400}
                                    key={i}
                                />
                            );
                            break;
                    }
                }
                return (
                    <PSTimeslotWrapper
                        key={i}
                        timeslot={timeslotEvent.timeslot}
                    >
                        {child}
                    </PSTimeslotWrapper>
                );
            })}
        </StyledRoot>
    );
}

//<PSEventContainer events={timeslotEvent.events} hourHeight={400} />
