import React from "react";
import Page from "../../Components/Page";
import { styled, Typography } from "@mui/material";
import zarmlogo from "../../images/zarm_logo.png";

const StyledZARMLogo = styled('img')({
    width: "128px",
    maxWidth: "100%",
    marginBottom: "16px"
});

export default function Imprint() {
    return (
        <Page padding title="Imprint">
            <StyledZARMLogo src={zarmlogo} alt="ZARM logo" />
            <Typography variant="h6">Supplier</Typography>
            <Typography>
                ZARM Fab mbH
                <br />
                Am Fallturm
                <br />
                28359 Bremen
                <br />
                E-Mail: cospar@cospar-assembly.org
                <br />
                Internet: http://www.cospar-assembly.org
            </Typography>
            <br />
            <Typography variant="h6">Managing director</Typography>
            <Typography>Prof. Dr. Marc Avila</Typography>
            <br />
            <Typography variant="h6">Responsible for contents</Typography>
            <Typography>
                Thorsten Coordes
                <br />
                Tel.: +49 421-218-57879
                <br />
                Telefax: +49 421-218-98-57879
            </Typography>
            <br />
            <Typography variant="h6">Entry in commercial register</Typography>
            <Typography>
                Amtsgericht Bremen
                <br />
                Registernummer: HRB 12600
            </Typography>
            <br />
            <Typography variant="h6">VAT Identification number</Typography>
            <Typography>
                DE 114437671 (according to § 27a Umsatzsteuergesetz)
            </Typography>
            <br />
            <Typography variant="h6">Note on liability</Typography>
            <Typography>
                Although we carefully control contents, we do not assume any
                liability for the contents of external links. Solely the
                operators of these pages bear responsibility for the contents.
            </Typography>
            <br />
            <Typography variant="h6">ISSN</Typography>
            <Typography>1815-2619</Typography>
        </Page>
    );
}
