import { useMemo, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { loader } from "graphql.macro";
import {
    PagePresentationDetailsQuery,
    PagePresentationDetailsQueryVariables,
} from "../../../graphql/query-types";
import { BreadcrumbItem } from "../../../Components/Breadcrumb";
import { useBreadcrumbs } from "../../../CustomHooks/useBreadcrumbs";
import Loading from "../../../Components/Loading";
import Page from "../../../Components/Page";
import Paper from "../../../DataModels/Paper";
import Presentation from "../components/presentation/Presentation";

interface PagePresentationDetailsParams {
    id: string;
}

interface Props {
    paperId?: number;
}

const query = loader("../../../graphql/PagePresentationDetails.graphql");

export default function PagePresentationDetails(props: Props) {
    const params = useParams<PagePresentationDetailsParams>();
    const id = props.paperId ?? parseInt(params.id);
    const pageRef = useRef(null);

    const { loading, data } = useQuery<
        PagePresentationDetailsQuery,
        PagePresentationDetailsQueryVariables
    >(query, {
        variables: { id },
    });

    const breadcrumbs = useMemo<BreadcrumbItem[]>(
        () => [
            {
                name: "Scientific Program",
                link: "/browser",
            },
            {
                name: data?.paper?.session?.symposium?.short ?? "",
                link: `/browser/symposium/${data?.paper?.session?.symposium?.id}`,
            },
            {
                name: data?.paper?.session?.session ?? "",
                link: `/browser/session/${data?.paper?.session?.id}`,
            },
            {
                name: data?.paper?.unique ?? "",
                link: `/browser/presentation/${id}`,
            },
        ],
        [data?.paper, id]
    );
    useBreadcrumbs(breadcrumbs);

    if (loading) return <Loading />;
    if (!data || Object.keys(data).length <= 0)
        return <Typography>Error Loading data</Typography>;

    return (
        <Page padding title={"Scientific Program"} ref={pageRef}>
            <Presentation paper={data.paper as Paper} printRef={pageRef} />
        </Page>
    );
}
