import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from "@mui/icons-material/Settings";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { SvgIconProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ArticleIcon from '@mui/icons-material/Article';
import MapIcon from "@mui/icons-material/Map";
import HelpIcon from "@mui/icons-material/Help";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import Recap from "./pages/Recap";
import pageHome from "./pages/Home";
import pageCongress from "./features/scientificProgram/pages/PageCongress";
import pageSymposium from "./features/scientificProgram/pages/PageSymposium";
import pageSession from "./features/scientificProgram/pages/PageSession";
import pageTimetable from "./pages/TimeTable/Timetable";
import pageSettings from "./pages/Settings";
import pagePresentationDetails from "./features/scientificProgram/pages/PagePresentationDetails";
import pagePersonalSchedule from "./features/personalSchedule/pages/PersonalSchedule";
import pageImport from "./pages/Import";
import pageSearch from "./pages/Search";
import pageAuthorPage from "./pages/Author";
import pageCompany from "./pages/Company";
import pageAuthorList from "./pages/AuthorList";
import pageCompanyList from "./pages/CompanyList";
import pageTeamView from "./pages/TeamView";
import pageSwipeSelector from "./pages/SwipeSelector/SwipeSelector";
import pageImprint from "./pages/StaticPages/Imprint";
import pageHowTo from "./pages/HowTo";
import pageStatic from "./pages/StaticPages/Static";
import pageChatRouter from "./pages/chat/ChatRouter";
import pageChatRegister from "./pages/chat/ChatPageRegister";
import pageNextEvent from "./features/nextEvents/pages/NextEventsPage";
import { apolloClient } from "./App";
import { gql } from "@apollo/client";

import pageMap from "./pages/Map";
import useSettings from "./features/settings/hooks/useSettings";
import RecapPreview from "./pages/RecapPreview";
const pageDebugMap = React.lazy(() => import("./pages/DebugMap"));

export interface Route {
    path: string | string[];
    /*component: React.LazyExoticComponent<any>;*/
    component: any;
    title?: string;
    icon?: (props: SvgIconProps) => JSX.Element;
    imgsrc?: string;
    exact?: boolean;
    props?: any;
    category?: "featured" | "static";
}

const pageQuery = gql`
    query PageQuery($congress: Int) {
        congress(id: $congress) {
            id
            pages {
                id
                name
                content
                congress
                icon
            }
        }
    }
`;

const genStaticRoutes = () => {
    const staticRoutes: Route[] = [
        { path: "/", component: pageHome, title: "Home", icon: HomeIcon },
        // Commented out for now as it is not used. issue 65
        //{ path: "/chat/register", component: pageChatRegister },
        //{
        //    path: "/chat",
        //    component: pageChatRouter,
        //    title: "Chat",
        //    exact: false,
        //    icon: ChatBubbleOutlineIcon,
        //    category: "featured",
        //},
        {
            path: "/howto",
            component: pageHowTo,
            title: "How To",
            icon: HelpIcon,
        },
        {
            path: "/ps",
            component: pagePersonalSchedule,
            title: "Personal Schedule",
            icon: StarBorderIcon,
            category: "featured",
        },
        {
            path: "/browser",
            component: pageCongress,
            title: "Scientific Program",
            icon: LibraryBooksIcon,
            category: "featured",
        },
        { path: "/browser/symposium/:id", component: pageSymposium },
        { path: "/browser/session/:id", component: pageSession },
        {
            path: "/browser/presentation/:id",
            component: pagePresentationDetails,
        },
        {
            path: ["/timetable/:day", "/timetable"],
            component: pageTimetable,
            title: "Timetable",
            icon: CalendarTodayIcon,
            exact: false,
            category: "featured",
        },
        {
            path: "/settings",
            component: pageSettings,
            title: "Settings",
            icon: SettingsIcon,
        },
        {
            path: "/authors",
            component: pageAuthorList,
            title: "Authors",
            icon: PersonIcon,
        },
        {
            path: "/institutes",
            component: pageCompanyList,
            title: "Institutes",
            icon: BusinessIcon,
        },
        { path: "/import", component: pageImport },
        {
            path: "/search",
            component: pageSearch,
            title: "Search",
            icon: SearchIcon,
            category: "featured",
        },
        { path: "/author/:id", component: pageAuthorPage },
        { path: "/institutes/:id", component: pageCompany },
        {
            path: "/imprint",
            component: pageImprint,
            title: "Imprint",
            icon: BookmarkIcon,
        },
        // Commented out for now as it is not used. issue 65
        //{
        //    path: "/map",
        //    component: pageMap,
        //    title: "Map",
        //    icon: MapIcon,
        //    category: "featured",
        //},
        //{ path: "/map/debug", component: pageDebugMap },
        { path: "/team/:id", component: pageTeamView },
        { path: "/select", component: pageSwipeSelector },
        { path: "/next", component: pageNextEvent }
    ];

    return staticRoutes;
};

const genDynamicRoutes = async (congress: number) => {
    const queryResult = await apolloClient.query({
        query: pageQuery,
        variables: { congress },
    });
    const dynamicRoutes = queryResult.data.congress.pages.map((page: any) => {
        return {
            path: "/s/" + page.name.toLowerCase().replaceAll(" ", "_"),
            component: pageStatic,
            title: page.name,
            props: { pageId: page.id },
            imgsrc: page.icon ?? undefined,
            category: "static",
        };
    });

    const recaps = {
        path: "/news",
        component: Recap,
        props: { congress } ,
        title: "What’s On Today",
        icon: ArticleIcon,
        category: "featured",
    }

    const recapPreview = {
        path: "/news-preview",
        component: RecapPreview,
        props: { congress }
    }

    dynamicRoutes.push(recaps);
    dynamicRoutes.push(recapPreview);

    return dynamicRoutes;
};

export default function useRoutes() {
    const [staticRoutes, setStaticRoutes] = useState<Route[]>([]);
    const [dynamicRoutes, setDynamicRoutes] = useState<Route[]>([]);
    const settings = useSettings();


    useEffect(() => {
        setStaticRoutes(genStaticRoutes());
        if (settings.congress) {
            genDynamicRoutes(settings.congress)
                .then((res) => {
                    setDynamicRoutes(res);
                })
                .catch((err) => console.error(err));
        }
    }, [settings.congress]);

    return [...staticRoutes, ...dynamicRoutes];
}
