import React, { useMemo, useRef, useCallback } from "react";
import {
    ListSubheader,
    ListItem,
    ListItemText,
    Tabs,
    Tab,
    List,
    styled,
} from "@mui/material";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import useBoundingRect from "../CustomHooks/useBoundingRect";
import { useHistory } from "react-router-dom";

interface ItemData {
    id: number;
    text: string;
}
export type AOListData = [string, ItemData[]];

interface Props {
    data: AOListData[] | ItemData[];
    generateLinks: (item: ItemData) => string;
}

const itemSize = 64;

const StyledTable = styled("div")({
    display: "flex",
    flexDirection: "row",
});
const StyledList = styled(List)({
    flexGrow: 1,
    padding: 0,
    margin: 0,
});
const StyledTab = styled(Tab)({
    minWidth: 48,
    maxWidth: 48,
    minHeight: 48,
    maxHeight: 48,
});

// TODO
const StyledText = styled(ListItemText)({
    "& .MuiSlider-primary": {
        maxHeight: "3em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
});

function isItemDataArray(x: AOListData[] | ItemData[]): x is ItemData[] {
    return x.length > 0 && "id" in x[0] && "text" in x[0];
}

export default function AOListPage(props: Props) {
    const { ref: listRef, rect: listRect } = useBoundingRect();

    const history = useHistory<{ scroll: number | undefined }>();

    const fsListRef = useRef<FixedSizeList | null>(null);
    const fsListCallback = useCallback(
        (node: FixedSizeList) => {
            if (node) {
                fsListRef.current = node;
                if (typeof history.location.state?.scroll === "number") {
                    fsListRef.current.scrollToItem(
                        (history.location.state as any).scroll,
                        "center"
                    );
                }
            }
        },
        [history.location.state]
    );

    const numRows = useMemo(() => {
        if (isItemDataArray(props.data)) {
            return props.data.length;
        } else {
            return props.data.reduce<number>((prev, cur, i) => {
                return prev + cur[1].length + 1;
            }, 0);
        }
    }, [props.data]);

    const tabPositions = useMemo(() => {
        if (!isItemDataArray(props.data)) {
            let tempCalcOffset = 0;
            return props.data.map((letterGroup) => {
                const result = tempCalcOffset;
                tempCalcOffset += (letterGroup[1].length + 1) * itemSize;
                return result;
            });
        }
    }, [props.data]);

    const callLink = (item: ItemData, index: number) => {
        history.replace({
            state: { scroll: index },
        });
        history.push(props.generateLinks(item));
    };

    const renderRowHeader = (text: string, style: any) => {
        return <ListSubheader style={style}>{text}</ListSubheader>;
    };

    const renderRow = (item: ItemData, style: any, index: number) => {
        return (
            <ListItem
                style={style}
                button
                onClick={() => callLink(item, index)}
            >
                <StyledText primary={item.text} primaryTypographyProps={{style: { lineHeight: 1.2 }}}/>
            </ListItem>
        );
    };

    const Row = (args: any) => {
        if (props.data.length > 0) {
            if (isItemDataArray(props.data)) {
                return renderRow(
                    props.data[args.index],
                    args.style,
                    args.index
                );
            } else {
                let curFullIndex = 0;

                for (let i = 0; i < props.data.length; i++) {
                    const lastIndex = curFullIndex + props.data[i][1].length;

                    if (args.index >= curFullIndex && args.index <= lastIndex) {
                        if (args.index === curFullIndex) {
                            return renderRowHeader(
                                props.data[i][0],
                                args.style
                            );
                        } else {
                            return renderRow(
                                props.data[i][1][args.index - curFullIndex - 1],
                                args.style,
                                args.index
                            );
                        }
                    }

                    curFullIndex = lastIndex + 1;
                }
            }
        }

        return null;
    };

    return (
        <StyledTable sx={{ height: `calc(100vh - ${listRect?.top ?? 0}px` }}>
            {!isItemDataArray(props.data) ? (
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={0}
                    TabIndicatorProps={{ hidden: true }}
                >
                    {props.data.map((current, i) => (
                        <StyledTab
                            key={i}
                            label={current[0]}
                            onClick={() => {
                                fsListRef.current?.scrollTo(
                                    tabPositions ? tabPositions[i] : 0
                                );
                            }}
                        />
                    ))}
                </Tabs>
            ) : (
                <></>
            )}
            <StyledList
                ref={listRef}
                sx={{ height: `calc(100vh - ${listRect?.top ?? 0}px)` }}
            >
                <AutoSizer>
                    {(size: any) => (
                        <FixedSizeList
                            height={size.height ?? 0}
                            itemSize={itemSize}
                            itemCount={numRows}
                            width={size.width ?? 0}
                            ref={fsListCallback}
                        >
                            {Row}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </StyledList>
        </StyledTable>
    );
}
