import { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    Button,
} from "@mui/material";
import useNote from "../../../personalSchedule/hooks/useNote";

interface Props {
    paperId: number;
    open: boolean;
    closeDialog: () => void;
}

export default function NoteEdit(props: Props) {
    const note = useNote(props.paperId);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const text = note?.note ?? "";
        setInputValue(text);
    }, [note?.note]);

    function saveNote() {
        note?.set(inputValue);
        props.closeDialog();
    }

    return (
        <Dialog open={props.open} onClose={props.closeDialog}>
            <DialogContent dividers>
                <TextField
                    label="Note"
                    rows={5}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.closeDialog}>
                    Cancel
                </Button>
                <Button color="primary" onClick={saveNote}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
