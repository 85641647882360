import React, { useContext, useMemo, useRef } from "react";
import Page from "../Components/Page";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import {
    PageAuthorListQuery,
    PageAuthorListQueryVariables,
} from "../graphql/query-types";
import { Typography } from "@mui/material";
import AOListPage, { AOListData } from "../Components/AOListPage";
import useSearch from "../CustomHooks/useSearch";
import { CacheStatus, CacheStatusContext } from "../App";
import useSettings from "../features/settings/hooks/useSettings";

const query = loader("../graphql/PageAuthorList.graphql");

export default function AuthorList() {
    const congress = useSettings().congress;
    const cacheStatus = useContext(CacheStatusContext);
    const enablePage =
        cacheStatus === CacheStatus.READY ||
        cacheStatus === CacheStatus.SHOW_READY;

    const { data, error } = useQuery<
        PageAuthorListQuery,
        PageAuthorListQueryVariables
    >(query, {
        variables: { congress },
        skip: !enablePage,
    });

    const names = useMemo(() => {
        //Order Authors by the first Letter
        const names: AOListData[] = [];
        data?.congress?.authors
            ?.filter((a) => a !== undefined && a !== null)
            .sort(
                (a, b) =>
                    (a?.last?.localeCompare(b?.last ?? "") ||
                        a?.last?.localeCompare(b?.last ?? "")) ??
                    0
            )
            .forEach((author) => {
                //Build the Name String
                if (author && author.last && author.first) {
                    let name = author.last + ", " + author.first;

                    const letter = author.last.charAt(0).toUpperCase();
                    if (
                        names.length === 0 ||
                        (names[names.length - 1][0] + "a").localeCompare(
                            letter
                        ) !== 1
                    )
                        names.push([letter, []]);

                    names[names.length - 1][1].push({
                        id: author.id,
                        text: name,
                    });
                }
            });

        return names;
    }, [data]);

    const namesFlat = useMemo(() => {
        return names.map((a) => a[1]).flat();
    }, [names]);

    const fields = useRef(["text"]);
    const namesSearch = useSearch({
        data: namesFlat,
        fields: fields.current,
    });

    if (!enablePage) {
        return (
            <Page padding title="Authors">
                <Typography>
                    This page is only available if the offline mode is enabled
                </Typography>
            </Page>
        );
    }

    if (error) return <Typography>Error Loading Data</Typography>;

    return (
        <Page title="Authors">
            <AOListPage
                data={namesSearch.searched ? namesSearch.data : names}
                generateLinks={(item) => `/author/${item.id}`}
            />
        </Page>
    );
}
