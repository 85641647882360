import Page from "../Components/Page";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import {
    PageCompanyQuery,
    PageCompanyQueryVariables,
} from "../graphql/query-types";
import {
    Typography,
    List,
    ListItemButton,
    ListItemText,
    styled,
} from "@mui/material";
import { useParams, Link, LinkProps } from "react-router-dom";
import User from "../DataModels/User";
import createAuthorName from "../shared/createAuthorName";
import GroupIcon from "@mui/icons-material/Group";
import useSettings from "../features/settings/hooks/useSettings";

const StyledIcon = styled(GroupIcon)({
    gridArea: "icon",
    alignSelf: "start",
});

const StyledTitle = styled(Typography)({
    gridArea: "title",
    alignSelf: "start",
});

const StyledContentList = styled(List)({
    gridArea: "content",
});

const StyledSection = styled("div")({
    display: "grid",
    grid: "auto 1fr / auto 1fr",
    gridGap: "0 1em",
    gridTemplateAreas: '"icon title" ". content"',
    marginTop: "1rem",
});

const StyledAuthorChipListItem = styled(ListItemButton)<LinkProps>({
    margin: "0.3em",
    cursor: "pointer",
});

const query = loader("../graphql/PageCompany.graphql");
export default function Company() {
    const params: any = useParams();
    const sett = useSettings();

    const { data } = useQuery<PageCompanyQuery, PageCompanyQueryVariables>(
        query,
        {
            variables: { id: parseInt(params.id), congress: sett.congress! },
            returnPartialData: true,
            skip: !sett.congress
        }
    );

    const userLink = (user: User | null) => {
        return user?.id ? "/author/" + user?.id : "";
    };

    return (
        <Page padding>
            <StyledTitle variant="h5">{data?.company?.company}</StyledTitle>

            <StyledSection>
                <StyledIcon />
                <StyledTitle variant="h6">Authors</StyledTitle>
                <StyledContentList>
                    {data?.company?.users?.map((user, i) => {
                        const name = createAuthorName(user);
                        const link = userLink(user);
                        return (
                            name && (
                                <StyledAuthorChipListItem
                                    key={i}
                                    component={Link}
                                    to={link}
                                >
                                    <ListItemText>{name}</ListItemText>
                                </StyledAuthorChipListItem>
                            )
                        );
                    })}
                </StyledContentList>
            </StyledSection>
        </Page>
    );
}
