import { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import {
    Button,
    CircularProgress,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    Link,
} from "@mui/material";
import useHowTo from "../../../CustomHooks/useHowTo";
import { styled } from "@mui/system";
import useFavorites from "../hooks/useFavorites";
import useSettings from "../../settings/hooks/useSettings";
import useNotes from "../hooks/useNotes";

const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

const StyledPaper = styled("div")({
    padding: "0.5rem",
    margin: "20px 0 40px 0",
});

interface Props {
    shareQRCode?: string;
}

export default function PSShare(props: Props) {
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [uuid, setUUID] = useState<string | undefined>(undefined);

    const [syncFavorites, setSyncFavorites] = useState(true);
    const [syncNotes, setSyncNotes] = useState(true);

    const settings = useSettings();
    const favorites = useFavorites();
    const notes = useNotes();

    const selectHT = useHowTo("share-select");
    const genLinkHT = useHowTo("share-gen-link");
    const qrCodeHT = useHowTo("share-qrcode");

    useEffect(() => {
        if (active) {
            const toSend: any = {};
            if (syncFavorites) toSend.favorites = favorites;
            if (syncNotes) toSend.notes = notes;

            fetch(settings.dataServer + "/uploadData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toSend),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    setUUID(data.uuid);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setError(true);
                });
            setLoading(true);
        }
    }, [active, settings.dataServer, syncFavorites, syncNotes, favorites, notes]);

    if (!props.shareQRCode && !active) {
        return (
            <div>
                <FormControl>
                    <FormLabel>Select Data</FormLabel>
                    <FormGroup row={true} ref={selectHT}>
                        <FormControlLabel
                            label="Favorites"
                            control={
                                <Checkbox
                                    checked={syncFavorites}
                                    onChange={(e) =>
                                        setSyncFavorites(e.target.checked)
                                    }
                                />
                            }
                        />
                        <FormControlLabel
                            label="Notes"
                            control={
                                <Checkbox
                                    checked={syncNotes}
                                    onChange={(e) =>
                                        setSyncNotes(e.target.checked)
                                    }
                                />
                            }
                        />
                    </FormGroup>
                </FormControl>
                <Typography variant="body2">
                    This will upload your Data to our server and generate a
                    link, to access it. Note that the data is open to everyone
                    having this link.
                </Typography>
                <Button
                    onClick={() => setActive(true)}
                    variant="contained"
                    color="primary"
                    disabled={!(syncFavorites || syncNotes)}
                    ref={genLinkHT}
                >
                    Generate Link
                </Button>
            </div>
        );
    } else if (error) {
        return <Typography>Error Uploading Data</Typography>;
    }

    const link = props.shareQRCode ?? `${settings.webServer}/${settings.slug}/import?uuid=${uuid}`;

    return (
        <StyledRoot ref={qrCodeHT}>
            {loading ? <CircularProgress /> : <></>}
            {uuid || props.shareQRCode ? (
                <>
                    <StyledPaper>
                        <QRCode
                            value={link}
                            size={170}
                        />
                    </StyledPaper>
                    <Typography>
                        Scan the QR-Code above or share the link with the other
                        device:
                    </Typography>
                    <Link href={link}>{link}</Link>
                </>
            ) : null}
        </StyledRoot>
    );
}
