import React, { useContext } from "react";
import {
    IconButton,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    styled,
} from "@mui/material";
import Event from "../../../DataModels/Event";
import { Link, LinkProps } from "react-router-dom";
import tf from "../../../shared/timeFormat";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RoomIcon from "@mui/icons-material/Room";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { NotificationContext } from "../../../Notifications";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import useHowTo from "../../../CustomHooks/useHowTo";
import pd from "../../../shared/preventDefault";

const StyledRoot = styled(ListItemButton)<LinkProps>({
    borderLeft: "5px solid black",
});

const StyledSecondary = styled("div")({
    display: "flex",
    alignItems: "center",

    "& *": {
        marginRight: "0.5em",
    },
});

interface Props {
    classes?: any;
    style?: any;
    event: Event;
}

export default function PSEvent(props: Props) {
    const notifications = useContext(NotificationContext);

    const title =
        props.event.paper.status === "ACCEPTED" ? (
            <span>
                <b>{props.event.paper.unique}</b> - {props.event.paper.title}
            </span>
        ) : (
            <b>Withdrawn</b>
        );
    const timeStr = tf`t${props.event.begin} - t${props.event.end}`;

    let notificationAction = null;

    if (!notifications?.isLoading) {
        if (notifications?.notifications === null) {
            notificationAction = <NotificationImportantIcon />;
        } else if (
            notifications?.notifications?.includes(props.event.paper.id)
        ) {
            notificationAction = (
                <IconButton
                    onClick={pd(() =>
                        notifications.unsubscribe(props.event.paper.id)
                    )}
                >
                    <NotificationsIcon />
                </IconButton>
            );
        } else {
            notificationAction = (
                <IconButton
                    onClick={pd(() =>
                        notifications?.subscribe(props.event.paper.id)
                    )}
                >
                    <NotificationsNoneIcon />
                </IconButton>
            );
        }
    }

    const howToRef = useHowTo("pse-root");
    const howToNotification = useHowTo("psevent-notification");

    return (
        <StyledRoot
            style={{ borderLeftColor: props.event.paper.color ?? "#000000" }}
            component={Link}
            to={`/browser/presentation/${props.event.paper.id}`}
            ref={howToRef}
        >
            <ListItemText
                primary={title}
                secondary={
                    <div>
                        <StyledSecondary>
                            <AccessTimeIcon fontSize="inherit" />
                            {timeStr}
                        </StyledSecondary>
                        <StyledSecondary>
                            <RoomIcon fontSize="inherit" />{" "}
                            {props.event.room?.room}
                        </StyledSecondary>
                    </div>
                }
            />
            <ListItemSecondaryAction ref={howToNotification}>
                {notificationAction}
            </ListItemSecondaryAction>
        </StyledRoot>
    );
}
