import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import TimeslotSessionDetails from "./Details/TimeslotSessionDetails";
import TimeslotSessionPaperDetails from "./Details/TimeslotSessionPaperDetails";

export default function TimeDetail() {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                path={path + "/timeslot/:timeslot/symposium/:id/:type/:typeid"}
            >
                <TimeslotSessionPaperDetails />
            </Route>
            <Route path={path + "/timeslot/:timeslot/symposium/:id"}>
                <TimeslotSessionDetails />
            </Route>
        </Switch>
    );
}
