import { Dispatch, SetStateAction, useEffect, useState } from "react";


export default function useLocalStorage<T>(key: string): [T|null, Dispatch<SetStateAction<T|null>>];
export default function useLocalStorage<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>];
export default function useLocalStorage<T>(key: string, defaultValue?: T): [T|null, Dispatch<SetStateAction<T|null>>] {
    const [value, setValue] = useState<T|null>(() => {
        const storageValue = localStorage.getItem(key);
        if (storageValue) {
            return JSON.parse(storageValue);
        } else {
            return defaultValue ?? null;
        }
    });

    useEffect(() => {
        if (value) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }, [key, value]);

    return [
        value,
        setValue
    ]
}