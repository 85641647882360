import { useContext, useEffect } from "react";
import { HowToContext } from "../pages/HowTo";
import useBoundingRect from "./useBoundingRect";

const useHowTo = (howToId: string) => {
    const { ref, rect } = useBoundingRect();
    const [, setRects] = useContext(HowToContext) ?? [null, () => {}];

    useEffect(() => {
        if (rect !== undefined) {
            setRects((a) => {
                a[howToId] = rect;
                return { ...a };
            });
        }
    }, [setRects, rect, howToId]);

    return ref;
};

export default useHowTo;
