import { CSSProperties } from "react";
import { styled } from "@mui/material";

const StyledUnique = styled("span")({
    padding: "0.4em",
    borderRadius: "0.3em",
    color: "white",
    display: "block",
    whiteSpace: "nowrap",
    textAlign: "center",
    minWidth: "1.5em",
});

interface Props {
    color?: string | null;
    children: string;
    style?: CSSProperties;
    className?: any;
}

export default function Unique(props: Props) {
    return (
        <StyledUnique
            className={props.className}
            style={{
                backgroundColor: props.color ?? "#333333",
                ...props.style,
            }}
        >
            {props.children}
        </StyledUnique>
    );
}
