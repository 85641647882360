import { useCallback, useContext, useEffect, useState } from "react";
import { MatrixContext } from "../../matrix";

export default function useAllUnreadCount(): number {
    const client = useContext(MatrixContext)?.client;
    const [count, setCount] = useState(0);

    const recalculateData = useCallback(() => {
        if (client) {
            setCount(client.getRooms().reduce((cur, room) => {
                return cur + (room.getUnreadNotificationCount() ?? 0);
            }, 0));
        }
    }, [client]);

    useEffect(() => {
        if (client) {
            recalculateData();
            const syncListener = (state: string, prevState: string) => {
                recalculateData();
            }
            client.addListener("sync", syncListener);
            return () => {
                client.removeListener("sync", syncListener);
            }
        }
    }, [client, recalculateData]);

    return count;
}
