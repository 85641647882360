import PSEvent from "./PSEvent";
import Event from "../../../DataModels/Event";
import { styled } from "@mui/material";

interface Props {
    classes?: any;
    events: Event[];
    hourHeight: number;
}

const StyledRoot = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
});

export default function PSPosterContainer(props: Props) {
    props.events.sort((a, b) => {
        return a.paper.unique?.localeCompare(b.paper.unique ?? "") ?? 0;
    });

    if (props.events.length > 0) {
        return (
            <StyledRoot>
                {props.events.map((event, i) => (
                    <PSEvent event={event} key={i} />
                ))}
            </StyledRoot>
        );
    } else {
        return null;
    }
}
