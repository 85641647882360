import { useState } from "react";
import { Collapse, Typography, styled } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import Session from "../DataModels/Session";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const StyledExpandWrapper = styled("div")({
    width: "100%",
    textAlign: "center",
    "& > *": {
        display: "inline-block",
    },
    cursor: "pointer",
});

interface Props {
    session: Session;
}

export default function SessionDescription(props: Props) {
    const [expandDesc, setExpandDesc] = useState(false);

    return (
        <div>
            <Collapse in={expandDesc} collapsedSize="2.5em">
                <Typography component="span">
                    {ReactHtmlParser(props.session.description || "")}
                </Typography>
            </Collapse>
            <StyledExpandWrapper onClick={() => setExpandDesc(!expandDesc)} className="no-print">
                {expandDesc ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </StyledExpandWrapper>
        </div>
    );
}
