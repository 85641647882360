import {
    IconButton,
    Paper,
    Typography,
    styled,
    useMediaQuery,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState, useRef, createRef, Fragment } from "react";
import Day from "../../Components/TimeTable/Day";
import Loading from "../../Components/Loading";
import { useQuery } from "@apollo/client";
import TimeDetail from "./TimeDetail";
import { useParams, Link } from "react-router-dom";
import Page from "../../Components/Page";
import Timeslot from "../../DataModels/Timeslot";
import { loader } from "graphql.macro";
import CloseIcon from "@mui/icons-material/Close";
import { FilterEntry } from "../../Components/Filter";
import Symposium from "../../DataModels/Symposium";
import Unique from "../../Components/unique/Unique";
import useFilter from "../../CustomHooks/useFilter";
import tf from "../../shared/timeFormat";
import useSettings from "../../features/settings/hooks/useSettings";

const StyledRootGridPaper = styled(Paper)({
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    height: "100%",
});

const StyledTimeTableGrid = styled("div")({
    transitionDuration: "1s",
    gridColumn: "1 / span 3",
    gridRow: "1",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    display: "grid",
    width: "100%",
    gridAutoRows: "auto",
    gridAutoFlow: "column",
    gridTemplateRows: "45px",
});

const StyledArrowLeft = styled("div")({
    gridRow: 1,
    gridColumn: 1,
    zIndex: 75,
});

const StyledArrowRight = styled("div")({
    gridRow: 1,
    gridColumn: 3,
    zIndex: 75,
});

const StyledTime = styled("span")({
    position: "sticky",
    display: "flex",
    alignItems: "start",
    transform: "translate(0,-.5em)",
    justifyContent: "center",
    left: 0,
    backgroundColor: "#ffffff",
    zIndex: 50,
    width: "50px",
    borderRight: "1px solid lightgrey",
    gridColumn: 1,
});

const StyledDetail = styled("div")({
    gridColumn: "3 / span 6",
    gridRow: "1 / span 9",
    padding: "20px 20px",
    transitionDelay: "250ms",
    transitionDuration: "250ms",
    overflowX: "hidden",
    boxSizing: "border-box",
});

interface TimeTableParams {
    day: string;
}

export interface DayData {
    [key: number]: Timeslot[];
}

const query = loader("../../graphql/PageTimetable.graphql");

export default function Timetable() {
    const settings = useSettings();

    const smallDisplay = useMediaQuery("(max-width: 1000px)");

    let params = useParams<TimeTableParams>();

    let details = params.day ? parseInt(params.day) : undefined;

    const minDaySize = 250;

    const [width, setWidth] = useState(0);

    const widthRef = useRef<HTMLDivElement>(null);
    const tableGrid = createRef<HTMLDivElement>();

    function changeWidth() {
        if (widthRef && widthRef.current) {
            setWidth(widthRef.current.clientWidth);
        }
    }

    useEffect(() => {
        if (tableGrid.current) {
            changeWidth();
            setTimeout(changeWidth, 1000);
            window.addEventListener("resize", changeWidth);

            return function () {
                window.removeEventListener("resize", changeWidth);
            };
        }
    });

    //Fetch Data
    const { loading, error, data } = useQuery(query, {
        variables: { congress: settings.congress },
    });

    let days: DayData[] = [];
    let timeOffset: number = 0;
    let timesSet = new Set<number>();
    //Filter Timeslots
    const timeslotsFiltered = data?.congress.timeslots.filter(
        (timeslot: Timeslot) => {
            return true;
            return (
                timeslot.desc_id === 1 ||
                timeslot.desc_id === 4 ||
                timeslot.desc_id === 5 ||
                timeslot.desc_id === 7 ||
                timeslot.desc_id === 13 ||
                timeslot.desc_id === 14 ||
                timeslot.desc_id === 17 ||
                timeslot.desc_id === 18
            );
        }
    );

    timeslotsFiltered
        ?.slice()
        .sort((a: any, b: any) => a.begin - b.begin)
        .forEach((timeslot: Timeslot) => {
            if (timeslot.begin) {
                if (timeOffset === 0) {
                    timeOffset = new Date(timeslot.begin * 1000).setUTCHours(
                        0,
                        0,
                        0,
                        0
                    );
                }

                let zeroDate = new Date(timeslot.begin * 1000 - timeOffset);
                let dayIndex = zeroDate.getUTCDate() - 1;

                const daytime = timeslot.begin * 1000 - dayIndex * 24 * 60 * 60 * 1000;

                if (!days[dayIndex]) days[dayIndex] = [];
                if (!days[dayIndex][daytime]) days[dayIndex][daytime] = [];
                days[dayIndex][daytime].push(timeslot);
                timesSet.add(daytime);
            }
        });

    let numDaysVisible = Math.min(Math.floor(width / minDaySize), days.length);
    let dayWidth = (width - 51) / numDaysVisible;
    if (!isFinite(dayWidth)) dayWidth = 0;

    const times = Array.from(timesSet).sort((a, b) => a - b);

    let timesStr = times.map((time) => tf`t${time / 1000}`);

    let filterEntrys: FilterEntry<number>[] = data?.congress.symposiums.map(
        (symp: Symposium) => {
            return {
                title: symp.title,
                value: symp.id,
                avatar: (
                    <Unique color={symp.color} style={{ marginRight: "1rem" }}>
                        {symp.short ?? ""}
                    </Unique>
                ),
            };
        }
    );

    const [filter, filterSelected] = useFilter("Filter", filterEntrys);

    // useEffect(() => {
    //     days.forEach((day, i) => {
    //         const timesl = day[0];
    //         if (timesl && timesl.begin) {
    //             const tsdate = new Date(timesl.begin * 1000);
    //             const now = new Date();
    //             if (
    //                 tsdate.getDate() === now.getDate() &&
    //                 tsdate.getMonth() === now.getMonth() &&
    //                 tsdate.getFullYear() === now.getFullYear()
    //             ) {
    //                 tableGrid.current?.scrollTo({
    //                     left: dayWidth * i,
    //                     behavior: "auto",
    //                 });
    //             }
    //         }
    //     });
    // });

    if (error) return <Typography>Error loading data</Typography>;
    if (loading) return <Loading />;

    if (smallDisplay && details !== undefined) {
        return (
            <Page padding title="Timetable">
                <TimeDetail />
            </Page>
        );
    } else {
        return (
            <Page padding title="Timetable" noContainer>
                {filter}
                <StyledRootGridPaper ref={widthRef}>
                    {details === undefined ? (
                        <>
                            <StyledArrowLeft
                                onClick={() =>
                                    tableGrid.current?.scrollBy({
                                        left: -dayWidth,
                                        behavior: "smooth",
                                    })
                                }
                            >
                                <IconButton>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </StyledArrowLeft>
                            <StyledArrowRight
                                onClick={() =>
                                    tableGrid.current?.scrollBy({
                                        left: dayWidth,
                                        behavior: "smooth",
                                    })
                                }
                            >
                                <IconButton>
                                    <ChevronRightIcon />
                                </IconButton>
                            </StyledArrowRight>
                        </>
                    ) : (
                        <StyledArrowRight>
                            <IconButton component={Link} to="/timetable">
                                <CloseIcon />
                            </IconButton>
                        </StyledArrowRight>
                    )}
                    <StyledTimeTableGrid
                        ref={tableGrid}
                        sx={{
                            gridTemplateColumns:
                                "auto repeat(" + days.length + ", 1fr)",
                        }}
                    >
                        <StyledTime />
                        {timesStr.map((str, i) => {
                            return (
                                <Fragment key={i}>
                                    <StyledTime>{str}</StyledTime>
                                </Fragment>
                            );
                        })}
                        {days.map((_, i) => {
                            if (details === undefined || details === i) {
                                return (
                                    <Day
                                        number={i}
                                        translate={(details ?? 0) * dayWidth}
                                        column={i + 2}
                                        key={i}
                                        data={{
                                            daydata: days[i],
                                            symposiums:
                                                data.congress.symposiums,
                                            times
                                        }}
                                        width={dayWidth}
                                        filter={filterSelected}
                                    />
                                );
                            } else {
                                return (
                                    <div
                                        style={{
                                            width: dayWidth,
                                            gridColumn: i + 2,
                                        }}
                                    ></div>
                                );
                            }
                        })}
                        <StyledDetail
                            sx={{
                                visibility:
                                    details === undefined
                                        ? "hidden"
                                        : "visible",
                                opacity: details === undefined ? "0" : "1",
                                width: `${(numDaysVisible - 1) * dayWidth}px`,
                            }}
                        >
                            {details !== undefined ? <TimeDetail /> : <></>}
                        </StyledDetail>
                    </StyledTimeTableGrid>
                </StyledRootGridPaper>
            </Page>
        );
    }
}
