import { styled, Typography } from "@mui/material";
import Timeslot from "../../../DataModels/Timeslot";

const StyledRoot = styled('div')({
    marginBottom: 32,
});

const StyledContent = styled('div')({
    padding: 0,
    "&:last-child": {
        paddingBottom: 0,
    },

    "& > *": {
        margin: 16,
        "&:last-child": {
            marginBottom: 24,
        },
    },
});

interface Props {
    classes?: any;
    timeslot: Timeslot;
    children?: JSX.Element | null;
}

export default function PSTimeslotWrapper({
    classes: propClasses,
    timeslot,
    children,
}: Props) {
    const beginDate = new Date((timeslot.begin ?? 0) * 1000);
    const beginStr = beginDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
        timeZone: "Europe/Berlin"
    });

    const endDate = new Date((timeslot.end ?? 0) * 1000);
    const endStr = endDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
        timeZone: "Europe/Berlin"
    });

    const timeStr = `${beginStr} - ${endStr}`;

    return (
        <StyledRoot>
            <Typography variant="h5" className="no-print">{timeslot.desc}</Typography>
            <Typography variant="subtitle1" className="no-print">{timeStr}</Typography>
            <StyledContent>{children}</StyledContent>
        </StyledRoot>
    );
}
