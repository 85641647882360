import UniqueList from "../../../../Components/unique/UniqueList";
import UniqueListItem from "../../../../Components/unique/UniqueListItem";
import Symposium from "../../../../DataModels/Symposium";

interface Props {
    symposiums: Symposium[];
}

export default function SymposiumList({ symposiums }: Props) {
    const sorter = new Intl.Collator("de", { numeric: true });
    const symposiumSorted = [...symposiums].sort((a, b) =>
        sorter.compare(a.short ?? "", b.short ?? "")
    );

    return (
        <UniqueList>
            {symposiumSorted.map((symposium, i) => (
                <UniqueListItem
                    title={symposium.title ?? ""}
                    color={symposium.color ?? "#333333"}
                    link={"/browser/symposium/" + symposium.id}
                    unique={symposium.short ?? ""}
                    i={i}
                />
            ))}
        </UniqueList>
    );
}
