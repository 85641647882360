import { LinkNoDecoration } from "./LinkNoDecoration";

interface Props {
    name: string;
    link: string;
}

export default function BreadLink(props: Props) {
    return (
        <LinkNoDecoration to={props.link}>
            {props.name}
        </LinkNoDecoration>
    );
}
