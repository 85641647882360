import { useEffect, useState, useContext, useMemo } from "react";
import { NavBarOptionsContext } from "../Components/Navbar";
import Fuse from "fuse.js";

interface Return<T> {
    data: T[];
    indexReady: boolean;
    searched: boolean;
}

export default function useSearch<T>(options: {
    data: T[];
    search?: string;
    fields: string[];
}): Return<T> {
    const [worker, setWorker] = useState<Worker>();
    const [indexReady, setIndexReady] = useState(false);
    const [results, setResults] = useState<Fuse.FuseResult<T>[]>();

    const [navbarSearch, setNavbarSearch] =
        useContext(NavBarOptionsContext).search;
    const useCustomSearchInput = options.search !== undefined;
    useEffect(() => {
        if (!useCustomSearchInput) {
            setNavbarSearch("");
        }
    }, [useCustomSearchInput]); // eslint-disable-line react-hooks/exhaustive-deps

    const search = useMemo(
        () => options.search ?? navbarSearch ?? "",
        [options.search, navbarSearch]
    );
    const fields = options.fields;

    const workerHandler = (res: any) => {
        switch (res.data.action) {
            case "create":
                setIndexReady(res.data.result);
                break;
            case "search":
                setResults(res.data.result);
                break;
        }
    };

    // Start and stop worker
    useEffect(() => {
        const tempWorker = new Worker("/search.worker.js");
        tempWorker.onmessage = workerHandler;
        setWorker(tempWorker);
        tempWorker.postMessage({
            action: "create",
            msgData: { data: options.data, fields },
        });
        return () => {
            tempWorker.terminate();
        };
    }, [options.data, fields]);

    useEffect(() => {
        const timeoutHandler = setTimeout(() => {
            worker?.postMessage({
                action: "search",
                msgData: search,
            });
        }, 700);
        return () => {
            clearTimeout(timeoutHandler);
        };
    }, [search, options.data, worker]);

    return {
        data: results?.map((r) => r.item) ?? [],
        indexReady,
        searched: !!search,
    };
}
