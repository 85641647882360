import React, { Dispatch, SetStateAction } from "react";
import useLocalStorage from "../../../CustomHooks/useLocalStorage";
import Notes from "../models/Notes";

export const NoteContext = React.createContext<[Notes, Dispatch<SetStateAction<Notes>>]|null>(null);

interface Props {
    children: React.ReactNode;
}

export function NoteContextProvider({children}: Props) {
    const noteState = useLocalStorage<Notes>("notes", {});

    return (
        <NoteContext.Provider value={noteState}>
            {children}
        </NoteContext.Provider>
    );
}