import React from "react";
import {
    Button,
    Container,
    styled,
} from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SettingsProvider } from "./features/settings/context/SettingsContext";
import Imprint from "./pages/StaticPages/Imprint";
import useCongressSettings from "./features/settings/hooks/useCongressSettings";
import CongressSelectorItem from "./CongressSelectorItem";

const App = React.lazy(() => import("./App"));

const CongressContainer = styled("div")({
    display: "flex",
    gap: "24px",
    flexDirection: "column",
    padding: "24px",
    margin: "0 auto",
    maxWidth: "1272px",
});

const CongressGrid = styled("div")({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr));",
    alignItems: "stretch",
    gap: "24px",
});

const ImprintLink = styled("a")({
    color: "#999",
    textDecoration: "none",
});

export default function CongressSelector() {
    const congressSettings = useCongressSettings();

    if (congressSettings.settings && !congressSettings.error) {
        return (
            <BrowserRouter basename="/">
                <Switch>
                    {congressSettings.settings.map((item) => (
                        <Route path={`/${item.slug}`}>
                            <SettingsProvider congress={item.congress}>
                                <App />
                            </SettingsProvider>
                        </Route>
                    ))}
                    <Route path={`/imprint`}>
                        <Container maxWidth="md">
                            <Button component="a" href="/">
                                Back to Congress Selector
                            </Button>
                        </Container>
                        <Imprint />
                    </Route>
                    <Route>
                        <CongressContainer>
                            <CongressGrid>
                                {congressSettings.settings.map((item) => (
                                    <CongressSelectorItem settings={item} />
                                ))}
                            </CongressGrid>
                            <ImprintLink href="/imprint">Imprint</ImprintLink>
                        </CongressContainer>
                    </Route>
                </Switch>
            </BrowserRouter>
        );
    } else if (congressSettings.error) {
        return <p>Error loading Settings</p>
    } else {
        return;
    }
}
