import Event from "../../../DataModels/Event";
import { List } from "@mui/material";
import PSEvent from "./PSEvent";

interface Props {
    classes?: any;
    events: Event[];
    hourHeight: number;
    extraRows?: number[];
}

export default function PSEventContainer(props: Props) {
    //Sort Events by Time
    props.events.sort((a, b) => {
        return a.begin - b.begin;
    });

    if (props.events.length > 0) {
        return (
            <List>
                {props.events.map((event, i) => (
                    <PSEvent event={event} key={i} />
                ))}
            </List>
        );
    } else {
        return null;
    }
}