import { commonSettings } from "../settings";

export function stripRoomId(roomId: string): string|boolean {
    const regex = /!(\w+):\w+/;
    const res = roomId.match(regex);

    if (res?.length === 2) {
        return res[1];
    } else {
        return false;
    }
}

export function expandRoomId(stripedRoomId: string): string {
    return `!${stripedRoomId}:${commonSettings.matrixServerName}`;
}