import logo from "../images/COSPAR_logo_blu-green.png";
import { CircularProgress, styled, Typography } from "@mui/material";

const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    "& > *": {
        padding: "40px",
    },
});

export default function LoadingScreen() {
    return (
        <StyledRoot>
            <img className="cospar-logo-reduced" src={logo} alt="" />
            <Typography variant="h4">COSPAR APP</Typography>
            <CircularProgress />
            <Typography color="textSecondary" variant="caption">
                Loading
            </Typography>
        </StyledRoot>
    );
}
