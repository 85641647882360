import { useContext, useEffect, useId } from "react";
import { NavBarOptionsContext } from "../Components/Navbar";

export default function useMenu(title: string, onClick: () => unknown, enable?: boolean) {
    const id = useId();
    const [, setMenu] = useContext(NavBarOptionsContext).menu;

    useEffect(() => {
        if (enable !== false) {
            setMenu(m => [
                ...(m ?? []),
                {
                    id,
                    title,
                    click: onClick
                }
            ]);
    
            return () => {
                setMenu(m => m?.filter(i => i.id !== id));
            }
        }
    }, [title, setMenu, id, enable])
}