import { List, Paper, styled } from "@mui/material";
import React from "react";
import HDSession from "../../../../DataModels/HDSession";
import PosterTime from "../../../../DataModels/PosterTime";
import Timeslot from "../../../../DataModels/Timeslot";
import tf from "../../../../shared/timeFormat";

const StyledDate = styled("div")({
    position: "sticky",
    zIndex: 20,
    "& div": {
        marginTop: 10,
        position: "relative",
        width: "100%",
        padding: 10,
        color: "white",
        boxSizing: "border-box",
    },
});

const StyledBreak = styled("span")({
    display: "flex",
    alignItems: "center",
    margin: "1rem 0",
    color: "grey",
    "&:before": {
        content: '""',
        borderBottom: "1px solid grey",
        flexGrow: 1,
        margin: "0 2rem",
    },
    "&:after": {
        content: '""',
        borderBottom: "1px solid grey",
        flexGrow: 1,
        margin: "0 2rem",
    },
});

interface Props {
    hdspt: HDSession | PosterTime;
    stickTop: number;
    color: string;
    last_break?: Timeslot | null;
    children: React.ReactNode;
    extra_timeslots?: Timeslot[];
}

export default function PresentationListTimeslot(props: Props) {
    const { hdspt, stickTop, color, last_break, children, extra_timeslots } =
        props;
    if (!hdspt.timeslot) return null;
    return (
        <div>
            {last_break ? (
                <StyledBreak>
                    {tf`Break (t${last_break.begin ?? 0} - t${
                        last_break.end ?? 0
                    })`}
                </StyledBreak>
            ) : (
                <StyledDate
                    sx={{
                        top: stickTop,
                        "& div": { backgroundColor: color },
                    }}
                >
                    <Paper elevation={0}>
                        <b>{hdspt.room?.room}</b>
                        {extra_timeslots ? (
                            [hdspt.timeslot, ...extra_timeslots].map((x_ts) => (
                                <>
                                    <br />
                                    <b>{tf` - d${x_ts?.begin ?? 0}`}</b>
                                </>
                            ))
                        ) : (
                            <b>{tf` - d${hdspt.timeslot?.begin ?? 0}`}</b>
                        )}
                    </Paper>
                </StyledDate>
            )}
            <List>{children}</List>
        </div>
    );
}
