import React, { useMemo, useState, useEffect } from "react";
import ical from "ical-generator";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import {
    CalendarDownloadQuery,
    CalendarDownloadQueryVariables,
} from "../../../graphql/query-types";
import { Typography, Button } from "@mui/material";
import useFavorites from "../hooks/useFavorites";

const query = loader("../../../graphql/CalendarDownload.graphql");

export default function PSDownload() {
    const favorites = useFavorites();
    const [dataUrl, setDataUrl] = useState("");
    const [noEvents, setNoEvents] = useState(true);

    const { data } = useQuery<
        CalendarDownloadQuery,
        CalendarDownloadQueryVariables
    >(query, {
        variables: { ids: favorites },
    });

    const blob = useMemo(() => {
        const cal = ical({});

        let addedEvent = true;
        if (data && data.papers) {
            data?.papers?.forEach((paper) => {
                paper?.times?.forEach((time, i) => {
                    if (paper.rooms) {
                        const start = new Date(((time?.begin ?? 0) - 3600) * 1000);
                        const end = new Date(((time?.end ?? 0) - 3600) * 1000)

                        cal.createEvent({
                            summary: paper?.title ?? "",
                            start,
                            end,
                            description: paper.abstract ?? "",
                            location: paper?.rooms[i]?.room ?? ""
                        });
                        addedEvent = false;
                    }
                });
            });
        }

        setNoEvents(addedEvent);

        const calStr = cal.toString();

        const blob = new Blob([calStr], {
            type: "text/calendar",
        });

        return blob;
    }, [data]);

    useEffect(() => {
        const url = URL.createObjectURL(blob);
        setDataUrl(url);

        return () => {
            URL.revokeObjectURL(url);
        };
    }, [blob]);

    if (noEvents) {
        return (
            <div>
                <Typography variant="body1">No Events selected</Typography>
            </div>
        );
    } else {
        return (
            <div>
                <Typography variant="body1">
                    Click 'Download' to download your personal schedule as an
                    iCalendar (*.ics) file. This file can be imported into many
                    calendar applications. Note that android is not able to
                    import ics files to the google calendar. You need to import
                    it in a web browser or via third-party apps.
                </Typography>
                <Button
                    href={dataUrl}
                    download="cospar_schedule.ics"
                    color="primary"
                    variant="contained"
                >
                    Download
                </Button>
            </div>
        );
    }
}
