import { Chip, styled } from "@mui/material";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import Paper from "../../../../DataModels/Paper";
import createAuthorName from "../../../../shared/createAuthorName";

interface Props {
    paper?: Paper;
    disableStyle?: boolean;
}

const StyledAuthorChip = styled(Chip)({
    cursor: "pointer",
});
const StyledAuthorWrapper = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    gap: ".5em",
});

export function AuthorsChipList(props: Props) {
    return (
        <StyledAuthorWrapper
            style={{ display: props.disableStyle ? "contents" : undefined }}
        >
            <Link
                to={"/author/" + props.paper?.author?.id}
                className="noLinkDecoration"
            >
                <StyledAuthorChip
                    icon={<PersonIcon />}
                    label={createAuthorName(props.paper?.author)}
                    size="small"
                />
            </Link>
            {props.paper?.presenting_author && (
                <Link
                    to={"/author/" + props.paper?.presenting_author?.id}
                    className="noLinkDecoration"
                >
                    <StyledAuthorChip
                        icon={<CoPresentIcon />}
                        label={createAuthorName(props.paper?.presenting_author)}
                        size="small"
                    />
                </Link>
            )}
            {props.paper?.co_authors && props.paper.co_authors.map((coAuthor, i) => (
                coAuthor.id !==
                    props.paper?.presenting_author?.id && (
                    <Link
                        to={"/author/" + coAuthor.id}
                        className="noLinkDecoration"
                        key={i}
                    >
                        <StyledAuthorChip
                            label={createAuthorName(coAuthor)}
                            size="small"
                        />
                    </Link>
            )))}
            {props.paper?.team && (
                <Link
                    to={"/team/" + props.paper.team.id}
                    className="noLinkDecoration"
                >
                    <StyledAuthorChip
                        label={`Team: ${props.paper.team.name}`}
                        size="small"
                    />
                </Link>
            )}
        </StyledAuthorWrapper>
    );
}
