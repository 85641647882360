import React from "react";
import { styled } from "@mui/material";

const StyledRoot = styled("div")({
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
});



export default function UniqueList({ children }: React.PropsWithChildren<{}>) {
    return (
        <StyledRoot>
            {children}
        </StyledRoot>
    );
}
