import { useCallback, useContext, useMemo } from "react";
import { FavoriteContext } from "../context/FavoriteContext";

interface Favorite {
    isFavorite: boolean;
    add: () => void;
    remove: () => void;
    toggle: () => void;
}

export default function useFavorite(paperId: number|number[]): Favorite|null {
    const paperIds = useMemo(() => Array.isArray(paperId) ? paperId : [paperId], [paperId]);
    const [favorites, setFavorites] = useContext(FavoriteContext) ?? [null, null];

    const isFavorite = useMemo(() => paperIds?.every(id => favorites?.includes(id)), [paperIds, favorites]);

    const remove = useCallback(() => {
        if (setFavorites) {
            setFavorites(f => f.filter(id => !paperIds.includes(id)));
        }
    }, [paperIds, setFavorites]);

    const add = useCallback(() => {
        if (setFavorites) {
            setFavorites(f => [...f, ...paperIds]);
        }
    }, [paperIds, setFavorites]);

    const toggle = useCallback(() => {
        if (isFavorite) {
            remove();
        } else {
            add();                
        }
    }, [isFavorite, add, remove]);

    if (favorites === null) return null;

    return {
        isFavorite,
        add,
        remove,
        toggle
    };
}