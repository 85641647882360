import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { IconButton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import useFavorite from "../../../personalSchedule/hooks/useFavorite";

interface Props {
    paperIds: number[];
}

const SessionListItem = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const history = useHistory();
    const favorite = useFavorite(props.paperIds);

    
    const openSwipe = () => {
        history.push("/select", { ids: props.paperIds });
    };

    return (
        <>
            <IconButton onClick={openSwipe}>
                <AddToPhotosIcon />
            </IconButton>
            {favorite && (
                <IconButton
                    onClick={favorite.toggle}
                    ref={ref}
                >
                    {favorite.isFavorite ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
            )}
        </>
    );
});

export default SessionListItem;