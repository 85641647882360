import { useCallback, useContext } from "react";
import { NoteContext } from "../context/NoteContext";

interface Note {
    note: string|null;
    set: (text: string, id?: number) => void;
}

export default function useNote(paperId: number|null): Note|null {
    const [notes, setNotes] = useContext(NoteContext) ?? [null, null];

    const note = (paperId !== null) ? notes?.[paperId] ?? null:null;

    const set = useCallback((text: string) => {
        if (setNotes && paperId !== null) {
            if (text) {
                setNotes(n => ({ ...n, [paperId]: text }));
            } else {
                let q: any;
                setNotes(n => ({ ...n, [paperId]: q }));
            }
        }
    }, [paperId, setNotes]);

    if (notes === null) return null;

    return {
        note,
        set
    };
}