import { Typography, styled } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Loading from "../../../Components/Loading";
import TimeDisplay from "../../../Components/TimeDisplay";
import Paper from "../../../DataModels/Paper";
import { loader } from "graphql.macro";
import SessionDescription from "../../../Components/SessionDescription";
import useSettings from "../../../features/settings/hooks/useSettings";
import SessionHeader from "../../../features/scientificProgram/components/session/SessionHeader";
import SessionJoinChatButton from "../../../features/scientificProgram/components/session/SessionJoinChatButton";
import PresentationList from "../../../features/scientificProgram/components/presentation/PresentationList";

const StyledTimes = styled("div")({
    marginLeft: "10px",
});

interface TimeslotSessionPaperDetailsParams {
    typeid: string;
    type: string;
}

const queryHDSession = loader(
    "../../../graphql/PageTimeslotSessionPaperDetailsHDSession.graphql"
);

const queryPosterTime = loader(
    "../../../graphql/PageTimeslotSessionPaperDetailsPosterTime.graphql"
);

export default function TimeslotSessionPaperDetails() {
    const params = useParams<TimeslotSessionPaperDetailsParams>();
    const id = parseInt(params.typeid);
    const cid = useSettings().congress;

    let dataReq: any = undefined;
    if (params.type === "hdsession") {
        dataReq = queryHDSession;
    } else if (params.type === "postertime") {
        dataReq = queryPosterTime;
    }

    const {
        loading,
        error,
        data: rawData,
    } = useQuery(dataReq, {
        variables: { id, cid },
    });

    if (loading) return <Loading />;
    if (error) return <Typography>Error loading Data</Typography>;

    let data = undefined;
    if (params.type === "hdsession") {
        data = rawData.hd_session;
    } else if (params.type === "postertime") {
        data = rawData.poster_time;
    }

    console.log(data);

    return (
        <div>
            <SessionHeader
                session={data.session}
                color={data.session.symposium.color}
            />
            <SessionDescription session={data.session} />
            <StyledTimes>
                <TimeDisplay timeslot={data.timeslot} />
            </StyledTimes>
            <br></br>
            {data.session?.chat && (
                <SessionJoinChatButton roomId={data.session?.chat ?? ""} />
            )}
            <PresentationList
                stick={0}
                color={data.session.symposium.color}
                dateWidth="100%"
                links={(paper: Paper) => {
                    return "/browser/presentation/" + paper.id;
                }}
                hd_sessions={rawData.hd_session ? [rawData.hd_session] : []}
                poster_times={rawData.poster_time ? [rawData.poster_time] : []}
            />
        </div>
    );
}
