import { ButtonBase, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Unique from "./Unique";

export interface Props {
    unique: string;
    title: string;
    color: string;
    link?: string;
    action?: JSX.Element;
    i: number;
}

export default function UniqueListItem({ unique, title, color, link, action, i }: Props) {
    return (
        <>
            <Unique
                color={color}
                style={{
                    gridRow: i + 1,
                    gridColumn: 1,
                    margin: "8px",
                }}
            >
                {unique ?? ""}
            </Unique>
            <Typography
                style={{
                    gridRow: i + 1,
                    gridColumn: 2,
                    margin: "8px",
                }}
            >
                {title}
            </Typography>
            <ButtonBase
                component={Link}
                to={link ?? ""}
                style={{
                    gridRow: i + 1,
                    gridColumnStart: 1,
                    gridColumnEnd: 4,
                    alignSelf: "stretch",
                    justifySelf: "stretch",
                }}
            />
            <div style={{ gridRow: i + 1, gridColumn: 3 }}>
                {action}
            </div>
        </>
    )
}