import { useEffect, useRef, ChangeEvent } from "react";
import { TextField, styled } from "@mui/material";

interface Props {
    onLostFocus?: () => unknown;
    open: boolean;
    focusOnOpen?: boolean;
    onChange?: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    value?: string;
}

const StyledSearch = styled(TextField)({
    transitionDuration: "0.3s",
    flexGrow: 0,
});

export default function SearchInput(props: Props) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.focusOnOpen && props.open) {
            inputRef.current?.focus();
        }
    }, [props.focusOnOpen, props.open]);

    return (
        <StyledSearch
            color="primary"
            sx={{
                width: props.open ? "100%" : "0%",
                visibility: props.open ? "visible" : "hidden",
            }}
            InputProps={{ style: { color: "white" } }}
            inputRef={inputRef}
            onBlur={props.onLostFocus}
            placeholder="Search ..."
            value={props.value}
            onChange={props.onChange}
        />
    );
}
