import { Fragment, useState } from "react";
import { Typography, Button, styled, IconButton } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { Link, LinkProps } from "react-router-dom";
import { AuthorsChipList } from "./AuthorsChipList";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import Paper from "../../../../DataModels/Paper";
import useHowTo from "../../../../CustomHooks/useHowTo";
import useNote from "../../../personalSchedule/hooks/useNote";
import useFavorite from "../../../personalSchedule/hooks/useFavorite";
import TimeDisplay from "../../../../Components/TimeDisplay";
import NoteEdit from "./NoteEdit";
import BusinessIcon from '@mui/icons-material/Business';
import AffiliationDialog from "./AffiliationDialog";

const StyledButton = styled(Button)({
    flexGrow: 1,
    maxWidth: "12em",
    flexBasis: 0,
    display: "flex",
    flexDirection: "column",
});
const StyledButtonLink = styled(StyledButton)<LinkProps>();
const StyledButtonWrapper = styled("div")({
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "16px 0",
});
const StyledTimeWrapper = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    marginTop: "0.5rem",

    "& > *": {
        marginRight: "1rem",
        flexGrow: "1",
    },
});
const StyledChipList = styled("div")({
    marginTop: ".5em",
});
const StyledTitle = styled("div")({
    display: "flex",
    justifyContent: "space-between",
});

interface PresentationProps {
    paper?: Paper;
    printRef?: React.MutableRefObject<null>;
}

export default function Presentation(props: PresentationProps) {
    const [openNoteDialog, setOpenNoteDialog] = useState(false);
    const [openAffDialog, setOpenAffDialog] = useState(false);

    const howToRef = useHowTo("pd-favorite");
    const note = useNote(props.paper?.id ?? null);
    const favorite = useFavorite(props.paper?.id ?? 0);

    const handlePrint = useReactToPrint({
        content: () => props.printRef?.current ?? null,
    });

    return (
        <div>
            <StyledTitle>
                <Typography variant="h5" component="h1">
                    {props.paper?.title ?? ""}
                </Typography>
                {props.printRef && (
                    <IconButton onClick={handlePrint} className="no-print">
                        <PrintIcon />
                    </IconButton>
                )}
            </StyledTitle>

            <StyledChipList>
                <AuthorsChipList paper={props.paper} />

                {props.paper?.team && (
                    <>
                        <Typography variant="h6">
                            {props.paper.team.name}
                        </Typography>
                        <Typography>{props.paper.team.authors}</Typography>
                    </>
                )}
            </StyledChipList>

            <StyledTimeWrapper>
                {props.paper?.times?.map((time, i) => {
                    if (props.paper?.rooms)
                        return (
                            <Fragment key={i}>
                                <div>
                                    <TimeDisplay
                                        timeslot={time}
                                        room={props.paper?.rooms[i]}
                                    />
                                </div>
                            </Fragment>
                        );
                    else return null;
                })}
            </StyledTimeWrapper>

            <StyledButtonWrapper className="no-print">
                {/* TODO */}
                {favorite && (
                    <StyledButton
                        color="inherit"
                        onClick={favorite.toggle}
                        ref={howToRef}
                    >
                        {favorite.isFavorite ? (
                            <StarIcon />
                        ) : (
                            <StarBorderIcon />
                        )}
                        Add to Schedule
                    </StyledButton>
                )}
                <StyledButton
                    color="inherit"
                    onClick={() => setOpenNoteDialog(true)}
                >
                    <ChatBubbleOutlineIcon />
                    Edit Note
                </StyledButton>
                <StyledButton
                    color="inherit"
                    onClick={() => setOpenAffDialog(true)}
                >
                    <BusinessIcon />
                    View Affiliations
                </StyledButton>
                {/*props.paper?.rooms && props.paper?.rooms?.length > 0 && (
                    <StyledButtonLink
                        color="inherit"
                        component={Link}
                        to={`/map?room=${props.paper?.rooms[0].id}`}
                    >
                        <ExploreOutlinedIcon />
                        Map
                    </StyledButtonLink>
                )*/}
            </StyledButtonWrapper>

            {note ? (
                <div>
                    <Typography variant="h6" component="h2">
                        Note
                    </Typography>
                    <Typography variant="body1">{note?.note ?? ""}</Typography>
                </div>
            ) : (
                <></>
            )}

            <div>
                <Typography variant="h6" component="h2">
                    Description
                </Typography>
                <Typography variant="body1">
                    {props.paper?.abstract ?? ""}
                </Typography>
            </div>

            <NoteEdit
                paperId={props.paper?.id ?? 0}
                open={openNoteDialog}
                closeDialog={() => setOpenNoteDialog(false)}
            />

            {props.paper && <AffiliationDialog open={openAffDialog} paper={props.paper} onClose={() => setOpenAffDialog(false)} />}
        </div>
    );
}
