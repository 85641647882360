import { Route, Switch, useLocation } from "react-router-dom";
import useNavbarHeight from "./CustomHooks/useNavbarHeight";
import { Route as MyRoute } from "./Routes";

interface Props {
    routes: MyRoute[];
    left: number;
}

export default function PageWrapper(props: Props) {
    const location = useLocation();
    const navBarHeight = useNavbarHeight();

    return (
        <div style={{position: "fixed", left: props.left, right: 0, top: navBarHeight, bottom: 0, overflowY: "auto"}}>
            <Switch key={location.pathname}>
                {props.routes.map(
                    ({ path, component: Component, exact, props: routeProps }) => {
                        return (
                            <Route
                                key={path.toString()}
                                path={path}
                                exact={exact ?? true}
                            >
                                <Component {...routeProps}></Component>
                            </Route>
                        );
                    }
                )}
            </Switch>
        </div>
    );
}
