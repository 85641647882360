import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
} from "@mui/material";

export interface FilterEntry<T> {
    title: string;
    value: T;
    avatar?: JSX.Element;
}

interface Props<T> {
    title?: string;
    entrys: FilterEntry<T>[];
    selected?: T[];
    onChange?: (entrys: T[]) => unknown;
}

export default function Filter<T>(props: Props<T>) {
    const [selected, setSelected] = useState<T[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            setSelected([...(props.selected ?? [])]);
        }
    }, [open, props.selected]);

    const cancel = () => {
        setOpen(false);
    };

    const save = () => {
        setOpen(false);

        if (props.onChange) props.onChange(selected);
    };

    const toggleSelection = (value: T) => {
        const index = selected.indexOf(value);

        if (index >= 0) {
            selected.splice(index, 1);
        } else {
            selected.push(value);
        }

        setSelected([...selected]);
    };

    return (
        <div>
            <Button
                onClick={() => setOpen(true)}
                style={{ marginBottom: "0.5rem" }}
            >
                <FilterListIcon />
                Filter
            </Button>
            <Dialog open={open} fullWidth>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <List>
                        {props.entrys.map((data, i) => (
                            <ListItem
                                button
                                dense
                                key={i}
                                onClick={() => toggleSelection(data.value)}
                            >
                                {data.avatar && (
                                    <ListItemAvatar>
                                        {data.avatar}
                                    </ListItemAvatar>
                                )}
                                <ListItemText primary={data.title} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        checked={selected.includes(data.value)}
                                        onChange={() =>
                                            toggleSelection(data.value)
                                        }
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel}>Cancel</Button>
                    <Button onClick={save}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
