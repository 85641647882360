import React from "react";
import { Switch, FormControlLabel, FormControl } from "@mui/material";
import Page from "../Components/Page";
import useSettings from "../features/settings/hooks/useSettings";

/*const sizes = [
    { name: "KB", size: 1000 },
    { name: "MB", size: 1000000 },
    { name: "GB", size: 1000000000 }
];*/

export default function Settings() {
    const settings = useSettings();

    const handleCollisionChange = (
        _: React.ChangeEvent<{}>,
        checked: boolean
    ) => {
        if (settings.changeUserSettings) {
            settings.changeUserSettings("showPSCollision", !settings.showPSCollision);
        }
    };

    return (
        <Page padding title="Settings">
            {/*<Typography>Size on disk: {diskSize}</Typography>*/}
            <FormControl>
                <FormControlLabel
                    control={<Switch checked={settings.showPSCollision} />}
                    label="Show collision warning when selecting new favorites"
                    onChange={handleCollisionChange}
                />
            </FormControl>
        </Page>
    );
}
