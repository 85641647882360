import React, { useMemo } from "react";
import useLocalStorage from "../../../CustomHooks/useLocalStorage";
import { commonSettings } from "../../../settings";
import useCongressSettings from "../hooks/useCongressSettings";

export interface CommonSettings {
    dataServer: string;
    webServer: string;
    graphqlServer: string;
    matrixServer: string;
    matrixServerName: string;
}

export interface FeaturedLink {
    id: number;
    congress: number;
    label: string;
    link: string;
}

export interface CongressSettings {
    congress: number;
    slug: string;
    name: string;
    subtitle: string;
    icon: string;
    homeIcon: string;
    homeIconWidth: string;
    homeTitle: string;
    homeSubTitle: string;
    examplePaper: number;
    exampleSymposium: number;
    navBarIcons?: () => React.ReactNode;
    featured_links: FeaturedLink[];
}
interface CongressField {
    congress: number;
}

interface UserSettings {
    showPSCollision: boolean;
}

interface UserSettingsChanger {
    changeUserSettings<T extends keyof UserSettings>(key: T, value: UserSettings[T]): void;
}

export type Settings = CommonSettings & Partial<CongressSettings & CongressField> & Partial<UserSettings> & Partial<UserSettingsChanger>;

interface Props {
    congress: number;
    children: JSX.Element;
}

export const SettingsContext = React.createContext<Settings>(commonSettings);
export function SettingsProvider(props: Props) {
    const [userSettings, setUserSettings] = useLocalStorage<UserSettings>("user_settings", {
        showPSCollision: true
    });

    function changeUserSettings<T extends keyof UserSettings>(key: T, value: UserSettings[T]) {
        setUserSettings({
            ...userSettings,
            [key]: value
        })
    };

    const congressSettings = useCongressSettings();
    const congressSetting = useMemo(() => {
        return congressSettings.settings?.filter(con => con.congress === props.congress)[0];
    }, [congressSettings]);

    return (
        <SettingsContext.Provider value={{
            congress: props.congress,
            ...commonSettings,
            ...congressSetting,
            ...userSettings,
            changeUserSettings
        }}>
            {props.children}
        </SettingsContext.Provider>
    );
}
