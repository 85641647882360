import { gql, useQuery } from "@apollo/client";
import Page from "../../Components/Page";
import ReactHtmlParser from "react-html-parser";

const query = gql`
    query PageStaticQuery($id: Int) {
        page(id: $id) {
            id
            name
            congress
            content
        }
    }
`;

interface Props {
    pageId: number;
}

export default function Static(props: Props) {
    const { data, error } = useQuery(query, {
        variables: { id: props.pageId },
    });

    if (error) {
        console.error(error);
        return "Error loading page data";
    }

    console.log(data);

    return (
        <Page padding title={data?.page?.name}>
            {data && <p>{ReactHtmlParser(data.page.content)}</p>}
        </Page>
    );
}
