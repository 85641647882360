import { Alert, Snackbar } from "@mui/material";
import {
    createContext,
    useEffect,
    useState,
    useContext
} from "react";
import useSettings from "./features/settings/hooks/useSettings";
import { NotificationsBaseContext } from "./NotificationsBase";

export const NotificationContext =
    createContext<NotificationContextInterface | null>(null);

interface ProviderProps {
    children?: JSX.Element | null;
}
interface NotificationContextInterface {
    isLoading: boolean;
    notifications: number[] | null;
    subscribe: (id: number) => void;
    unsubscribe: (id: number) => void;
}

export function NotificationProvider(props: ProviderProps) {
    const settings = useSettings();

    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState<number[] | null>(null);
    const [errorMessage, setErrorMessage] = useState("");

    const notificationBase = useContext(NotificationsBaseContext);

    useEffect(() => {
        if (notificationBase?.token) {
            fetch(
                settings.dataServer + "/notifications",
                {
                    method: "GET",
                    headers: {
                        Authorization: notificationBase?.token,
                    },
                }
            )
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then(responseJSON => {
                if (responseJSON !== null) {
                    setNotifications(responseJSON);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
        } else if (!notificationBase?.isLoading) {
            setIsLoading(false);
            if (!notificationBase?.error && !notificationBase?.blocked) {
                setNotifications([]);
            }
        }
    }, [settings.dataServer, notificationBase?.token, notificationBase?.isLoading, notificationBase?.error ,notificationBase?.blocked]);

    const subscribe = async (id: number) => {
        if (
            notificationBase &&
            !notificationBase.blocked &&
            !notificationBase.error
        ) {
            let tempToken = notificationBase.token;

            if (tempToken === null) {
                tempToken = await notificationBase.requestToken();

                if (!tempToken) return;
            }

            fetch(settings.dataServer + "/notifications", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tempToken}`,
                },
                body: JSON.stringify({
                    abstract: id,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        setNotifications((not) => [...(not ?? []), id]);
                    } else {
                        throw new Error("Wrong status");
                    }
                })
                .catch(() => {
                    setErrorMessage("Failed to subscribe to Event");
                });
        }
    };

    const unsubscribe = async (id: number) => {
        if (
            notificationBase &&
            !notificationBase.blocked &&
            !notificationBase.error
        ) {
            let tempToken = notificationBase.token;

            if (tempToken === null) {
                tempToken = await notificationBase.requestToken();

                if (!tempToken) return;
            }

            fetch(settings.dataServer + "/notifications", {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tempToken}`,
                },
                body: JSON.stringify({
                    abstract: id,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        setNotifications(
                            (not) => not?.filter((a) => a !== id) ?? null
                        );
                    } else {
                        throw new Error("Wrong status");
                    }
                })
                .catch(() => {
                    setErrorMessage("Failed to unsubscribe from event");
                });
        }
    };

    return (
        <NotificationContext.Provider
            value={{
                isLoading,
                notifications,
                subscribe,
                unsubscribe,
            }}
        >
            <Snackbar
                open={!!errorMessage}
                autoHideDuration={6000}
                onClose={() => setErrorMessage("")}
            >
                <Alert onClose={() => setErrorMessage("")} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            {props.children}
        </NotificationContext.Provider>
    );
}
