import React from "react";
import { Typography, styled } from "@mui/material";

const StyledRoot = styled("div")({
    display: "flex",
    marginTop: "0.5em",
    "& > *": {
        marginRight: "0.5em",
    },
});

interface Props {
    icon?: JSX.Element;
    text?: string;
}

export default function IconText(props: Props) {
    return (
        <StyledRoot>
            {props.icon}
            <Typography variant="body1">{props.text}</Typography>
        </StyledRoot>
    );
}
