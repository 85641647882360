import React, { Suspense, useCallback, useReducer, useRef } from "react";
import Navbar, { NavBarOptionsProvider } from "./Components/Navbar";
import { Sidebar } from "./Components/Sidebar";
import { BrowserRouter } from "react-router-dom";

import { useState } from "react";
import Loading from "./Components/Loading";
import localForage from "localforage";
import PageWrapper from "./PageWrapper";
import { useEffect } from "react";
import { commonSettings } from "./settings";
import LoadingScreen from "./pages/LoadingScreen";
import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    ApolloProvider,
} from "@apollo/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { NotificationProvider } from "./Notifications";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { indigo as primary } from "@mui/material/colors";
import { MatrixProvider } from "./matrix";
import useRoutes from "./Routes";
import NotificationsBaseProvider from "./NotificationsBase";
import useBoundingRect from "./CustomHooks/useBoundingRect";
import { FavoriteContextProvider } from "./features/personalSchedule/context/FavoriteContext";
import useSettings from "./features/settings/hooks/useSettings";
import useLocalStorage from "./CustomHooks/useLocalStorage";
import useOfflineActive from "./features/settings/hooks/useOfflineActive";
import RecapDialog from "./Components/RecapDialog";

const theme = createTheme({
    palette: {
        primary: primary,
    },
});

//Apollo
const apolloCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                congress: (_, { args, toReference }) =>
                    toReference({ __typename: "Congress", id: args?.id }),
                session: (_, { args, toReference }) =>
                    toReference({ __typename: "Session", id: args?.id }),
                symposium: (_, { args, toReference }) =>
                    toReference({ __typename: "Symposium", id: args?.id }),
                paper: (_, { args, toReference }) =>
                    toReference({ __typename: "Paper", id: args?.id }),
                papers: (_, { args, toReference }) =>
                    args?.ids.map((id: number) =>
                        toReference({ __typename: "Paper", id: id })
                    ),
                hd_session: (_, { args, toReference }) =>
                    toReference({ __typename: "HDSession", id: args?.id }),
                timeslot: (_, { args, toReference }) =>
                    toReference({ __typename: "TimeSlot", id: args?.id }),
                poster_time: (_, { args, toReference }) =>
                    toReference({ __typename: "PosterTime", id: args?.id }),
                user: (_, { args, toReference }) =>
                    toReference({ __typename: "User", id: args?.id }),
                users: (_, { args, toReference }) =>
                    args?.ids.map((id: number) =>
                        toReference({ __typename: "User", id: id })
                    ),
                company: (_, { args, toReference }) =>
                    toReference({ __typename: "Company", id: args?.id }),
                room: (_, { args, toReference }) =>
                    toReference({ __typename: "Room", id: args?.id }),
                team: (_, { args, toReference }) =>
                    toReference({ __typename: "Team", id: args?.id }),
                page: (_, { args, toReference }) =>
                    toReference({ __typename: "Page", id: args?.id }),
                recaps: (_, { args, toReference }) =>
                    args?.ids.map((id: number) =>
                        toReference({ __typename: "Recap", id: id })
                    ),
                recapsByCongress: (_, { args, toReference }) =>
                    toReference({ __typename: "Recap", congress: args?.congress }),
            },
        },
    },
});

export const apolloClient = new ApolloClient({
    link: new HttpLink({
        uri: commonSettings.graphqlServer,
    }),
    cache: apolloCache,
});

export const NavBarRefContext =
    React.createContext<null | React.RefObject<HTMLElement>>(null);
export interface TopMessage {
    message: string;
    type: "error" | "warning" | "success";
    onClick?: () => void;
}
function topMessageReducer(
    state: TopMessage[],
    action: { object: TopMessage; method: "add" | "remove" }
) {
    switch (action.method) {
        case "add":
            return [...state, action.object];
        case "remove":
            return [...state.filter((i) => !Object.is(i, action.object))];
    }
}
type TopMessageContextType = [
    TopMessage[],
    React.Dispatch<{
        object: TopMessage;
        method: "add" | "remove";
    }>
];
export const TopMessageContext =
    React.createContext<null | TopMessageContextType>(null);

export enum CacheStatus {
    READY = "READY", // Everything is ready
    SHOW_READY = "SHOW_READY", // READY, but show it to the user
    ERROR = "ERROR", // Something went wrong
    FETCHING = "FETCHING", // Fetching cache data from server
    DISABLED = "DISABLED", // User has disabled offline usage
    ASK_PERMISSION = "ASK_PERMISSION", // Ask the user wheter he wants to enable offline usage
    LOADING = "LOADING", // The cache is being loaded from indexedDB
    INITIAL = "INITIAL", // The first state, it changes to FETCHING, LOADING, ASK_PERMISSION or DISABLED
}

export const CacheStatusContext = React.createContext<null | CacheStatus>(null);

export default function App() {
    const [sidebar, setSidebar] = useState(false);
    const topMessages = useReducer(topMessageReducer, []);
    const [cacheStatus, setCacheStatus] = useState<CacheStatus>(
        CacheStatus.INITIAL
    );
    const [updateAvailable, setUpdateAvailable] =
        useState<null | ServiceWorkerRegistration>(null);
    const routes = useRoutes();
    const settings = useSettings();
    const navBarRef = useRef<HTMLDivElement>(null);

    const [ activateOfflineMode, setActivateOfflineMode ] = useOfflineActive(settings.congress);
    const cacheStorageName = `${settings.congress}-cache`;

    const { rect: sideBarRect, ref: sideBarRef } = useBoundingRect();
    const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

    const doUpdate = useCallback(() => {
        updateAvailable?.waiting?.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
    }, [updateAvailable?.waiting]);

    const [, changeTopMessages] = topMessages;

    useEffect(() => {
        if (updateAvailable?.waiting) {
            const message: TopMessage = {
                message: "Update Available. Click here to install",
                type: "warning",
                onClick: doUpdate,
            };
            changeTopMessages({
                object: message,
                method: "add",
            });
            return () => {
                changeTopMessages({
                    object: message,
                    method: "remove",
                });
            };
        }
    }, [updateAvailable, changeTopMessages, doUpdate]);

    useEffect(() => {
        const topCacheMessages: { [key in CacheStatus]?: TopMessage } = {
            ASK_PERMISSION: {
                message: "Offline Support not enabled. Click here to enable.",
                type: "warning",
                onClick: () => setUseOfflineMode(true),
            },
            FETCHING: {
                message: "Downloading Congress Data...",
                type: "warning",
            },
            ERROR: {
                message: "Something went wrong",
                type: "error",
            },
            SHOW_READY: {
                message: "Offline Support activated",
                type: "success",
            },
        };
        const message = topCacheMessages[cacheStatus];
        if (message) {
            changeTopMessages({
                object: message,
                method: "add",
            });
            return () => {
                changeTopMessages({
                    object: message,
                    method: "remove",
                });
            };
        }
    }, [changeTopMessages, cacheStatus]);

    useEffect(() => {
        switch (cacheStatus) {
            case CacheStatus.INITIAL:
                if (activateOfflineMode === null) {
                    setCacheStatus(CacheStatus.ASK_PERMISSION);
                } else {
                    if (activateOfflineMode) {
                        localForage.getItem(cacheStorageName).then((cache) => {
                            if (cache !== null) {
                                setCacheStatus(CacheStatus.LOADING);
                            } else {
                                setCacheStatus(CacheStatus.FETCHING);
                            }
                        });
                    } else {
                        setCacheStatus(CacheStatus.DISABLED);
                    }
                }
                break;
            case CacheStatus.FETCHING:
            case CacheStatus.LOADING:
                const cacheWorker = new Worker(`/cache.worker.js`);

                cacheWorker.onmessage = (e) => {
                    switch (e.data.type) {
                        case "cache":
                            apolloCache.restore(e.data.data);
                            setCacheStatus(
                                cacheStatus === CacheStatus.FETCHING
                                    ? CacheStatus.SHOW_READY
                                    : CacheStatus.READY
                            );
                            break;
                        case "error":
                            setCacheStatus(CacheStatus.ERROR);
                            break;
                    }
                };

                cacheWorker.onerror = (err) => {
                    setCacheStatus(CacheStatus.ERROR);
                };

                cacheWorker.postMessage({
                    dataServerUrl: settings.dataServer,
                    congress: settings.congress,
                    cacheName: cacheStorageName
                });

                return () => {
                    cacheWorker.terminate();
                };
            case CacheStatus.SHOW_READY:
                setActivateOfflineMode(true);
                const timeout = setTimeout(
                    () => setCacheStatus(CacheStatus.READY),
                    3000
                );
                return () => {
                    clearTimeout(timeout);
                };
            case CacheStatus.ERROR:
                localForage.removeItem(cacheStorageName);
                setActivateOfflineMode(null);
                break;
        }
    }, [settings.dataServer, settings.congress, cacheStatus]);

    useEffect(() => {
        navigator.serviceWorker?.getRegistration().then(
            (reg) => {
                if (reg?.waiting) {
                    setUpdateAvailable(reg);
                }
            },
            () => {
                setCacheStatus(CacheStatus.ERROR);
            }
        );
    }, []);

    const setUseOfflineMode = (value: boolean) => {
        serviceWorkerRegistration.register({
            onUpdate: (reg) => setUpdateAvailable(reg),
            instant: true,
        });

        if (value === false) {
            setActivateOfflineMode(value);
        }
        setCacheStatus(value ? CacheStatus.FETCHING : CacheStatus.DISABLED);
    };

    if (
        cacheStatus !== CacheStatus.LOADING &&
        cacheStatus !== CacheStatus.INITIAL
    ) {
        return (
            <div>
                <ThemeProvider theme={theme}>
                    <ApolloProvider client={apolloClient}>
                        <TopMessageContext.Provider value={topMessages}>
                            <CacheStatusContext.Provider value={cacheStatus}>
                                <NotificationsBaseProvider>
                                    <MatrixProvider>
                                        <NotificationProvider>
                                            <FavoriteContextProvider>
                                                <NavBarOptionsProvider>
                                                    <NavBarRefContext.Provider
                                                        value={navBarRef}
                                                    >
                                                        <Suspense
                                                            fallback={
                                                                <Loading />
                                                            }
                                                        >
                                                            <BrowserRouter
                                                                basename={`/${settings.slug}`}
                                                            >
                                                                <Navbar
                                                                    ref={
                                                                        navBarRef
                                                                    }
                                                                    toggleSidebar={() => {
                                                                        setSidebar(
                                                                            (
                                                                                s
                                                                            ) =>
                                                                                !s
                                                                        );
                                                                    }}
                                                                    routes={
                                                                        routes
                                                                    }
                                                                    isMobile={
                                                                        isMobile
                                                                    }
                                                                    sidebarOpen={
                                                                        sidebar
                                                                    }
                                                                />
                                                                <Sidebar
                                                                    show={
                                                                        isMobile
                                                                            ? sidebar
                                                                            : true
                                                                    }
                                                                    isMobile={
                                                                        isMobile
                                                                    }
                                                                    openSidebar={() => {
                                                                        setSidebar(
                                                                            true
                                                                        );
                                                                    }}
                                                                    closeSidebar={() => {
                                                                        setSidebar(
                                                                            false
                                                                        );
                                                                    }}
                                                                    routes={
                                                                        routes
                                                                    }
                                                                    ref={
                                                                        sideBarRef
                                                                    }
                                                                />
                                                                <PageWrapper
                                                                    routes={
                                                                        routes
                                                                    }
                                                                    left={
                                                                        isMobile
                                                                            ? 0
                                                                            : sideBarRect?.right ??
                                                                              0
                                                                    }
                                                                />
                                                            </BrowserRouter>
                                                        </Suspense>
                                                    </NavBarRefContext.Provider>
                                                </NavBarOptionsProvider>
                                            </FavoriteContextProvider>
                                        </NotificationProvider>
                                    </MatrixProvider>
                                </NotificationsBaseProvider>
                            </CacheStatusContext.Provider>
                        </TopMessageContext.Provider>
                    </ApolloProvider>
                </ThemeProvider>
                <RecapDialog />
            </div>
        );
    } else {
        return <LoadingScreen />;
    }
}
