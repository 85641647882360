import { useQuery } from "@apollo/client";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { loader } from "graphql.macro";
import React from "react";
import { useParams } from "react-router-dom";
import Page from "../Components/Page";
import {
    PageTeamViewQuery,
    PageTeamViewQueryVariables,
} from "../graphql/query-types";

const query = loader("../graphql/PageTeamView.graphql");

export default function TeamView() {
    const params: any = useParams();

    const { data } = useQuery<PageTeamViewQuery, PageTeamViewQueryVariables>(
        query,
        {
            variables: { id: params.id },
            fetchPolicy: "cache-only",
        }
    );

    return (
        <Page padding>
            <Typography variant="h5">{data?.team?.name}</Typography>
            <Typography>{data?.team?.authors}</Typography>

            <Typography variant="h6">Papers</Typography>
            <List>
                {data?.team?.papers?.map((paper, i) => (
                    <ListItem key={i} button>
                        <ListItemText primary={paper.title} />
                    </ListItem>
                ))}
            </List>
        </Page>
    );
}
