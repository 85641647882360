import React, { useContext, useEffect, useState } from "react";
import Page from "../../../Components/Page";
import { Tabs, Tab } from "@mui/material";
import { NavBarOptionsContext } from "../../../Components/Navbar";
import PSTimeTable from "../components/PSTimeTable";
import PSDownload from "./PSDownload";
import PSShare from "./PSShare";

interface Props {
    favorites?: number[];
    tab?: number;
    shareQRCode?: string;
}

export default function PersonalSchedule(props: Props) {
    const [, setToAdd] = useContext(NavBarOptionsContext).toAdd;
    const [tab, setTab] = useState(props.tab ?? 0);

    const changeTabs = (event: React.ChangeEvent<{}>, value: number) => {
        setTab(value);
    };

    useEffect(() => {
        const tabs = (
            <Tabs
                value={tab}
                textColor="inherit"
                indicatorColor="secondary"
                onChange={changeTabs}
            >
                <Tab label="List"></Tab>
                <Tab label="Share"></Tab>
                <Tab label="Download"></Tab>
            </Tabs>
        );

        setToAdd(tabs);
    }, [setToAdd, tab]);

    return (
        <Page padding title="Personal Schedule">
            {tab !== 0 ? <></> : <PSTimeTable favorites={props.favorites} />}
            {tab !== 1 ? <></> : <PSShare shareQRCode={props.shareQRCode} />}
            {tab !== 2 ? <></> : <PSDownload />}
        </Page>
    );
}
