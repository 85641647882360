import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogActions, DialogProps, useMediaQuery, useTheme, Divider } from '@mui/material';
import Recap, { RecapInterface } from './Recap';

interface RecapDialogProps {
  recap: RecapInterface;
}

const RecapDialog = ({ recap }: RecapDialogProps) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = (event: {}, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  };
  
  return (
    <div>
      <Button onClick={() => setOpen(true)}>Open Modal Preview</Button>
      <div className='MuiContainer-root MuiContainer-maxWidthMd css-xdmu94-MuiContainer-root'>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          fullScreen={fullScreen}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent dividers={scroll === 'paper'}>
              <React.Fragment key={recap.id}>
                  <Recap recap={recap} />
              </React.Fragment>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose({}, '')}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default RecapDialog;