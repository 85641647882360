import {
    Chip,
    Collapse,
    IconButton,
    ListItemButton,
    Paper as MPaper,
    styled,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { AuthorsChipList } from "./AuthorsChipList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import tf from "../../../../shared/timeFormat";
import pd from "../../../../shared/preventDefault";
import Paper from "../../../../DataModels/Paper";
import useFavorite from "../../../personalSchedule/hooks/useFavorite";

const StyledItem = styled("div")({
    marginTop: 10,
    display: "flex",
});
const StyledTime = styled(MPaper)({
    padding: 10,
    color: "#ffffff",
    marginTop: 10,
    marginBottom: 10,
    zIndex: 10,
    alignSelf: "start",
});
const StyledPaper = styled(MPaper)({
    padding: "10px 10px 10px 30px",
    position: "relative",
    flexGrow: 1,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
});
const StyledPaperContent = styled("div")({
    flexGrow: 1,
});
const StyledButtonRoot = styled(ListItemButton)<LinkProps>({
    padding: 0,
    marginRight: -20,
    left: -20,
    flexGrow: 1,
});
const StyledChips = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: ".5em",
    margin: ".25em 0",
    flexWrap: "wrap",
});
const StyledChipCollapse = styled("div")({
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
});

interface Props {
    color: string;
    paper: Paper;
    timeIndex: number;
    link: string;
}

const defaultChipHeight = 24;

export default function PresentationListEvent(props: Props) {
    const { color, paper, timeIndex, link } = props;

    const [expanded, setExpanded] = useState<boolean | null>(null);
    const chipRef = useRef<HTMLDivElement>(null);

    const favorite = useFavorite(paper.id);

    useEffect(() => {
        const chipHeight = chipRef.current?.scrollHeight ?? defaultChipHeight;
        if (chipHeight > defaultChipHeight) {
            setExpanded(false);
        }
    }, []);

    return (
        <StyledItem>
            <StyledTime sx={{ background: color }} elevation={0}>
                {timeIndex >= 0
                    ? tf`t${paper.times![timeIndex].begin ?? 0}`
                    : "--:--"}
            </StyledTime>

            <StyledButtonRoot component={Link} to={link}>
                <StyledPaper elevation={0}>
                    <StyledPaperContent>
                        <b>{paper.unique}</b>
                        <StyledChipCollapse>
                            <Collapse
                                in={expanded ?? true}
                                collapsedSize="calc(24px + .5em)"
                            >
                                <StyledChips ref={chipRef}>
                                    {paper.status === "WITHDRAWN" && (
                                        <Chip
                                            label="withdrawn"
                                            color="error"
                                            size="small"
                                        />
                                    )}
                                    <AuthorsChipList
                                        paper={paper}
                                        disableStyle
                                    />
                                </StyledChips>
                            </Collapse>
                            <IconButton
                                onClick={pd(() => setExpanded(!expanded))}
                                className="no-print"
                            >
                                {expanded ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </IconButton>
                        </StyledChipCollapse>
                        {paper.title}
                    </StyledPaperContent>
                    {favorite && (
                        <IconButton
                            onClick={pd(favorite.toggle)}
                            className="no-print"
                        >
                            {favorite.isFavorite ? (
                                <StarIcon />
                            ) : (
                                <StarBorderIcon />
                            )}
                        </IconButton>
                    )}
                </StyledPaper>
            </StyledButtonRoot>
        </StyledItem>
    );
}
