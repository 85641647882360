import React from "react";
import { Typography, Button, styled } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import Symposium from "../../DataModels/Symposium";
import { DayData } from "../../pages/TimeTable/Timetable";
import tf from "../../shared/timeFormat";

const StyledHeader = styled(Typography)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
});

const StyledSymposium = styled(Button)<LinkProps>({
    color: "white",
    margin: "3px",
    display: "flex",
    minWidth: "32px",
    height: "32px",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8px"
});

const StyledDayWrapper = styled("div")({
    display: "contents",

    "& > *": {
        gridColumn: "inherit",
        borderRight: "1px solid lightgrey",
        borderBottom: "1px solid lightgrey",
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        scrollMarginLeft: "50px",
        width: "inherit",
        boxSizing: "border-box",
        padding: "8px",
        transitionDuration: "250ms",
    },
});

const StyledTimeContent = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    "& > span": {
        width: "100%",
        textAlign: "center",
        fontWeight: "600"
    }
});

const StyledCellContent = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "stretch",
    "& > *": {
        flex: 1,
        flexBasis: 1
    },
    "& > * + *": {
        borderLeft: "1px solid lightgrey"
    }
});

const StyledTime = styled("span")({
    color: "grey",
    fontWeight: "600"
})

interface Data {
    daydata: DayData;
    symposiums: Symposium[];
    times: number[];
}

interface Props {
    width: number;
    translate: number;
    data: Data;
    column: number;
    number: number;
    filter?: number[];
}

export default function Day(props: Props) {
    let dayString = "";

    const daydata = props.data.daydata;
    for (let key in daydata) {
        if (daydata[key].some(a => {
            if (a.begin) {
                let date = new Date(a.begin * 1000);
                dayString = date.toLocaleDateString("en-US", {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    timeZone: "Europe/Berlin"
                });
                return true;
            }
            return false;
        })) break;
    }

    let symposiums: Symposium[] = [];
    props.data.symposiums.forEach((symposium) => {
        let id = symposium.id;
        symposiums[id] = symposium;
    });

    // For the row span
    let last_non_empty = 0;
    const skip_times = props.data.times.reduce<number[]>((prev, cur, i) => {
        if (daydata[cur]) {
            last_non_empty = i;
        } else if (i) {
            prev[last_non_empty]++;
        }
        prev[i] = 1;
        return prev;
    }, []);

    return (
        <StyledDayWrapper
            sx={{
                width: props.width,
                gridColumn: props.column,
                "& > *": {
                    transform: props.translate
                        ? "translateX(-" + props.translate + "px)"
                        : "",
                },
            }}
        >
            <StyledHeader style={{ gridRow: 1 }}>{dayString}</StyledHeader>
            {props.data.times.map((time, i) => {
                const timeslots = daydata[time]?.sort((a,b) => (a?.desc_id ?? 0) - (b?.desc_id ?? 0));

                if (timeslots) {
                    return (
                        <React.Fragment key={i}>
                            <StyledCellContent style={{ gridRow: `${i+2} / span ${skip_times[i]}` }}>
                                {timeslots.map(timeslot => (
                                    <StyledTimeContent>
                                        <span>{timeslot.desc}</span>
                                        {timeslot.begin && timeslot.end ? <StyledTime>{tf`t${timeslot.begin} - t${timeslot.end}`}</StyledTime> : null}
                                        {timeslot?.symposiums?.map(
                                            (symposium, i2) => {
                                                if (
                                                    !props.filter ||
                                                    props.filter.length === 0 ||
                                                    props.filter.includes(
                                                        symposium.id
                                                    )
                                                ) {
                                                    let linkPath =
                                                        "/timetable/" +
                                                        props.number +
                                                        "/timeslot/" +
                                                        timeslot.id +
                                                        "/symposium/" +
                                                        symposium.id;

                                                    return (
                                                        <StyledSymposium
                                                            to={linkPath}
                                                            component={Link}
                                                            key={
                                                                timeslot.begin +
                                                                "-" +
                                                                i2
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    symposiums[
                                                                        symposium
                                                                            .id
                                                                    ].color ?? "",
                                                            }}
                                                        >
                                                            {
                                                                symposiums[
                                                                    symposium.id
                                                                ].short
                                                            }
                                                        </StyledSymposium>
                                                    );
                                                }
                                                return null;
                                            }
                                        )
                                        }
                                    </StyledTimeContent>
                                ))}
                            </StyledCellContent>
                        </React.Fragment>
                    );
                } else if (i === 0) {
                    return (
                        <StyledTimeContent
                            style={{ gridRow: `${i+2} / span ${skip_times[i]}` }}
                        ></StyledTimeContent>
                    );
                }
            })}
        </StyledDayWrapper>
    );
}
