import { styled } from "@mui/material";
import { useMemo } from "react";
import UniqueList from "../../../../Components/unique/UniqueList";
import UniqueListItem from "../../../../Components/unique/UniqueListItem";
import useHowTo from "../../../../CustomHooks/useHowTo";
import Session from "../../../../DataModels/Session";
import SessionListAction from "./SessionListAction";

const StyledRoot = styled("div")({
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
});

interface Props {
    sessions: Session[];
    color: string | null;
    uniquePrefix?: string;
    links: Function;
}

export default function SessionList(props: Props) {
    const sorter = new Intl.Collator("de", { numeric: true });
    const sessions = [...props.sessions].sort((a, b) =>
        sorter.compare(a.session ?? "", b.session ?? "")
    );

    const paperIdsPerSession = useMemo(
        () =>
            sessions.map((session) => session.papers?.map((paper) => paper.id)),
        [sessions]
    );
    const howToFav = useHowTo("session-list-fav");

    return (
        <StyledRoot>
            <UniqueList>
                {sessions.map((session, i) => (
                    <UniqueListItem
                        title={session.name ?? ""}
                        color={props.color ?? "#333"}
                        link={props.links(session)}
                        unique={(props.uniquePrefix ?? "") + session.session}
                        action={
                            <SessionListAction
                                paperIds={paperIdsPerSession[i] ?? []}
                                ref={i === 0 ? howToFav : undefined}
                            />
                        }
                        i={i}
                    />
                ))}
            </UniqueList>
        </StyledRoot>
    );
}
