import { useMemo } from "react";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import {
    PageSymposiumSessionQuery,
    PageSymposiumSessionQueryVariables,
    PageSymposiumSymposiumQuery,
    PageSymposiumSymposiumQueryVariables,
} from "../../../graphql/query-types";
import { BreadcrumbItem } from "../../../Components/Breadcrumb";
import { useBreadcrumbs } from "../../../CustomHooks/useBreadcrumbs";
import Loading from "../../../Components/Loading";
import Page from "../../../Components/Page";
import SymposiumHeader from "../components/symposium/SymposiumHeader";
import SessionList from "../components/session/SessionList";
import Symposium from "../../../DataModels/Symposium";
import Session from "../../../DataModels/Session";

const querySymposium = loader("../../../graphql/PageSymposiumSymposium.graphql");
const querySession = loader("../../../graphql/PageSymposiumSession.graphql");

interface PageSymposiumParams {
    id: string;
}

interface Props {
    symposiumId?: number;
}

export default function PageSymposium(props: Props) {
    const paramId = useParams<PageSymposiumParams>().id;
    const id = props.symposiumId ?? parseInt(paramId);

    const {
        loading: loadingSymposium,
        error: errorSymposium,
        data: dataSymposium,
    } = useQuery<
        PageSymposiumSymposiumQuery,
        PageSymposiumSymposiumQueryVariables
    >(querySymposium, {
        variables: { id },
        skip: !id,
    });

    const { error: errorSession, data: dataSession } = useQuery<
        PageSymposiumSessionQuery,
        PageSymposiumSessionQueryVariables
    >(querySession, {
        variables: { id },
        skip: !id,
    });

    const breadcrumbs = useMemo<BreadcrumbItem[]>(
        () => [
            {
                name: "Scientific Program",
                link: "/browser",
            },
            {
                name: dataSymposium?.symposium?.short ?? "",
                link: `/browser/symposium/${id}`,
            },
        ],
        [dataSymposium?.symposium?.short, id]
    );
    useBreadcrumbs(breadcrumbs);

    if (loadingSymposium) return <Loading />;
    if (errorSymposium || errorSession || !dataSymposium)
        return <Typography>Error Loading Data</Typography>;

    const symposium = dataSymposium.symposium;

    return (
        <Page title="Scientific Program" padding>
            <SymposiumHeader symposium={symposium as Symposium} />
            {dataSession ? (
                <SessionList
                    sessions={dataSession.symposium?.sessions ?? []}
                    color={symposium?.color ?? ""}
                    uniquePrefix={symposium?.short ?? undefined}
                    links={(session: Session) => {
                        return "/browser/session/" + session.id;
                    }}
                />
            ) : (
                <Loading />
            )}
        </Page>
    );
}
