import React, { useContext, useState, SetStateAction, Dispatch } from "react";
import {
    AppBar,
    Badge,
    IconButton,
    Menu,
    MenuItem,
    styled,
    Toolbar,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useLocation, useHistory } from "react-router-dom";
import { Route } from "../Routes";
import SearchIcon from "@mui/icons-material/Search";
import SearchInput from "./SearchInput";
import cospar_logo from "../images/COSPAR_logo_blu-green.png";
import twitter_logo from "../images/twitter.svg";
import facebook_logo from "../images/f_logo.png";
import linkedin_logo from "../images/li_logo.png";
import yt_logo from "../images/yt_icon_mono_dark.png";
import { TopMessageContext } from "../App";
import useAllUnreadCount from "../CustomHooks/matrix/useAllUnreadCount";
import CloseIcon from "@mui/icons-material/Close";
import Breadcumb, { BreadcrumbItem } from "./Breadcrumb";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSettings from "../features/settings/hooks/useSettings";

interface Props {
    toggleSidebar: () => void;
    routes: Route[];
    badgeValue?: number;
    isMobile: boolean;
    sidebarOpen: boolean;
}

const StyledMessageWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    zIndex: 1300,
});
const StlyedMessage = styled(Typography)({
    textAlign: "center",
    padding: ".2em",
    zIndex: 1300,
    userSelect: "none"
});

const StyledError = styled(StlyedMessage)({
    backgroundColor: "#e91e63",
});

const StyledWarning = styled(StlyedMessage)({
    backgroundColor: "#ffc107",
});

const StyledSuccess = styled(StlyedMessage)({
    backgroundColor: "#4caf50",
});

const Navbar = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const history = useHistory();
    const [searchOpen, setSearchOpen] = useState(false);
    const [title] = useContext(NavBarOptionsContext).title;
    const [search, setSearch] = useContext(NavBarOptionsContext).search;
    const [toAddTop] = useContext(NavBarOptionsContext).toAddTop;
    const [toAdd] = useContext(NavBarOptionsContext).toAdd;
    const [topMessages] = useContext(TopMessageContext) ?? [[]];
    const [menu] = useContext(NavBarOptionsContext).menu;
    const notificationCount = useAllUnreadCount();
    const settings = useSettings();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const location = useLocation();
    let isRootSite =
        (location.pathname.match(/^\/[^/?]*?\/?$/) != null ||
            location.pathname.match(/^\/s\/[^/?]*?\/?$/) != null) &&
        !location.search;

    let navIcon = (
        <>
            { props.isMobile && <IconButton
                edge="start"
                color="inherit"
                onClick={props.toggleSidebar}
            >
                <Badge badgeContent={notificationCount} color="secondary">
                    {props.sidebarOpen ? <CloseIcon /> : <MenuIcon />}
                </Badge>
            </IconButton>}
            {!isRootSite && (
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={history.goBack}
                >
                    <ArrowBack />
                </IconButton>
            )}
        </>
    );

    return (
        <div ref={ref}>
            <AppBar position="fixed" elevation={0} style={{ zIndex: 1300 }}>
                <Toolbar>
                    {navIcon}
                    {!searchOpen && (
                        <>
                            <Typography
                                variant="h6"
                                sx={{
                                    ...((searchOpen && {
                                        width: 0,
                                        visibility: "hidden",
                                    }) ||
                                        {}),
                                    flexGrow: 1,
                                }}
                                noWrap
                            >
                                {title}
                            </Typography>
                            <a
                                href="https://www.facebook.com/CommitteeOnSpaceResearch"
                                title="COSPAR Facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={facebook_logo}
                                    alt="COSPAR Facebook Logo"
                                    style={{
                                        width: "auto",
                                        height: "calc(1em + 12px)",
                                        marginRight: "1em",
                                    }}
                                />
                            </a>
                            <a
                                href="https://twitter.com/cosparhq"
                                title="COSPAR Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={twitter_logo}
                                    alt="COSPAR Twitter Logo"
                                    style={{
                                        width: "auto",
                                        height: "calc(1em + 12px)",
                                        marginRight: "1em",
                                    }}
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/committee-on-space-research-cospar"
                                title="COSPAR LinkedIn"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={linkedin_logo}
                                    alt="COSPAR LinkedIn Logo"
                                    style={{
                                        width: "auto",
                                        height: "calc(1em + 12px)",
                                        marginRight: "1em",
                                    }}
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UC66pk6Eb0kPKC5Uqx-D-RLw"
                                title="COSPAR YouTube"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={yt_logo}
                                    alt="Cospar YouTube Logo"
                                    style={{
                                        width: "auto",
                                        height: "calc(1em + 12px)",
                                        marginRight: "1em",
                                    }}
                                />
                            </a>
                            <a
                                href="https://cosparhq.cnes.fr/"
                                title="Committee on Space Research"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={cospar_logo}
                                    alt="Cospar logo"
                                    style={{
                                        width: "auto",
                                        height: "calc(1em + 24px)",
                                        marginRight: "1em",
                                    }}
                                />
                            </a>
                            {settings.navBarIcons && settings.navBarIcons()}
                        </>
                    )}
                    {search !== undefined ? (
                        <>
                            <IconButton
                                color="inherit"
                                onClick={() => setSearchOpen(true)}
                            >
                                <SearchIcon />
                            </IconButton>
                            <SearchInput
                                open={searchOpen}
                                onLostFocus={() => setSearchOpen(!!search)}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                focusOnOpen
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {toAddTop}
                    {menu && menu.length > 0 && (
                        <>
                            <IconButton
                                color="inherit"
                                onClick={handleOpenMenu}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                open={!!anchorEl}
                                anchorEl={anchorEl}
                                onClose={handleCloseMenu}
                            >
                                {menu.map((menuitem) => (
                                    <MenuItem onClick={menuitem.click} key={menuitem.id}>
                                        {menuitem.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </Toolbar>
                {toAdd ? toAdd : <></>}
            </AppBar>
            <Toolbar />
            {toAdd ? toAdd : <></>}
            <Breadcumb />
            <StyledMessageWrapper>
                {topMessages.map((msg) => {
                    switch (msg.type) {
                        case "error":
                            return (
                                <StyledError
                                    style={{
                                        cursor: msg.onClick
                                            ? "pointer"
                                            : undefined,
                                    }}
                                    onClick={() =>
                                        msg.onClick ? msg.onClick() : undefined
                                    }
                                >
                                    {msg.message}
                                </StyledError>
                            );
                        case "warning":
                            return (
                                <StyledWarning
                                    style={{
                                        cursor: msg.onClick
                                            ? "pointer"
                                            : undefined,
                                    }}
                                    onClick={() =>
                                        msg.onClick ? msg.onClick() : undefined
                                    }
                                >
                                    {msg.message}
                                </StyledWarning>
                            );
                        case "success":
                            return (
                                <StyledSuccess
                                    style={{
                                        cursor: msg.onClick
                                            ? "pointer"
                                            : undefined,
                                    }}
                                    onClick={() =>
                                        msg.onClick ? msg.onClick() : undefined
                                    }
                                >
                                    {msg.message}
                                </StyledSuccess>
                            );
                    }
                    return null;
                })}
            </StyledMessageWrapper>
        </div>
    );
});

export default Navbar;

export interface NavMenuItem {
    id: string;
    title: string;
    click: () => void;
}

interface OptionsProps {
    children: JSX.Element;
}
export interface NavBarOptions {
    title: [string, Dispatch<SetStateAction<string>>];
    toAddTop: [
        React.ReactNode | undefined,
        Dispatch<SetStateAction<React.ReactNode | undefined>>
    ];
    toAdd: [
        React.ReactNode | undefined,
        Dispatch<SetStateAction<React.ReactNode | undefined>>
    ];
    search: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
    breadcrumbs: [
        BreadcrumbItem[] | undefined,
        Dispatch<SetStateAction<BreadcrumbItem[] | undefined>>
    ];
    menu: [
        NavMenuItem[] | undefined,
        Dispatch<SetStateAction<NavMenuItem[] | undefined>>
    ];
}
export const NavBarOptionsContext = React.createContext<NavBarOptions>({
    title: ["", () => {}],
    toAddTop: [undefined, () => {}],
    toAdd: [undefined, () => {}],
    search: [undefined, () => {}],
    breadcrumbs: [undefined, () => {}],
    menu: [undefined, () => {}],
});
export function NavBarOptionsProvider(props: OptionsProps) {
    const initial: NavBarOptions = {
        title: useState(""),
        toAddTop: useState<React.ReactNode | undefined>(),
        toAdd: useState<React.ReactNode | undefined>(),
        search: useState<string | undefined>(),
        breadcrumbs: useState<BreadcrumbItem[] | undefined>(),
        menu: useState<NavMenuItem[] | undefined>(),
    };

    return (
        <NavBarOptionsContext.Provider value={initial}>
            {props.children}
        </NavBarOptionsContext.Provider>
    );
}
