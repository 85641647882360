import { apolloClient } from "../App";
import { gql } from "graphql.macro";

const RECAPS_BY_CONGRESS = gql`
    query RecapsByCongress($congress: Int!) {
        recapsByCongress(congress: $congress) {
            id
            congress
            date
            title
            content
        }
    }
`;

const RECAPS_BY_DATE = gql`
    query RecapsByDate($date: String!) {
        recapsByDate(date: $date) {
            id
            congress
            date
            title
            content
        }
    }
`;

export const getRecapsByCongress = async (congress: number) => {
    const queryResult = await apolloClient.query({
        query: RECAPS_BY_CONGRESS,
        variables: { congress },
    });
    const recaps = queryResult.data.recapsByCongress.map((recap: any) => {
        return processRecap(recap);
    });

    return recaps;
};

export const processRecap = (recap: any) => {
    const readableDate = new Date(parseInt(recap.date)).toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return {
        id: recap.id,
        date: readableDate,
        title: recap.title,
        congress: recap.congress,
        content: recap.content,
    };
}

export const getRecapsByDate = async (date: string) => {
    const queryResult = await apolloClient.query({
        query: RECAPS_BY_DATE,
        variables: { date },
    });
    const recaps = queryResult.data.recapsByDate.map((recap: any) => {
        return processRecap(recap);
    });

    return recaps;
};