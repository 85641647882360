import User from "../DataModels/User";

const createAuthorName = (author: User | null | undefined) => {
    if (author) {
        return (
            (author.first ? author.first + " " : "") +
            (author.last ? author.last + " " : "")
        );
    } else {
        return "";
    }
};

export default createAuthorName;
