import {
    createContext,
    useEffect,
    useState,
    useCallback,
    useContext,
} from "react";
import * as sdk from "matrix-js-sdk";
import { TopMessage, TopMessageContext } from "./App";
import { commonSettings } from "./settings";
import { NotificationsBaseContext } from "./NotificationsBase";

export const MatrixContext = createContext<MatrixContextState | null>(null);
interface MatrixState {
    user: string | null;
    client: sdk.MatrixClient;
    state: string;
}
export interface MatrixContextState extends MatrixState {
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

const createClient = () => {
    const keys = JSON.parse(window.localStorage.getItem("keys") ?? "{}");
    return sdk.createClient({
        baseUrl: commonSettings.matrixServer,
        sessionStore: new (sdk as any).WebStorageSessionStore(
            window.localStorage
        ),
        ...keys,
    });
};

type StateMessages = { [key: string]: TopMessage };
const stateMessages: StateMessages = {
    RECONNECTING: {
        message: "Reconnecting to chat server...",
        type: "warning",
    },
    ERROR: {
        message: "No Connection to the chat server",
        type: "error",
    },
};

interface MatrixProviderProps {
    children: React.ReactNode;
}

export const MatrixProvider = ({ children }: MatrixProviderProps) => {
    const [user, setUser] = useState<string | null>(null);
    const [state, setState] = useState<string>("");
    const [client] = useState<sdk.MatrixClient>(createClient);
    const [, changeTopMessages] = useContext(TopMessageContext) ?? [];

    const notificationBase = useContext(NotificationsBaseContext);

    useEffect(() => {
        if (
            user &&
            notificationBase?.isLoading === false &&
            notificationBase.error === false &&
            notificationBase.blocked === false &&
            notificationBase.token === null
        ) {
            notificationBase.requestToken();
        }
        (async () => {
            if (user && notificationBase?.token) {
                console.log("Set pusher");
                client.setPusher({
                    app_display_name: "Cospar PWA",
                    app_id: "org.cospar-assembly.app",
                    device_display_name: "Device",
                    kind: "http",
                    lang: "en",
                    data: {
                        url: "http://localhost:8080/_matrix/push/v1/notify",
                    },
                    pushkey: notificationBase.token,
                }).then(() => {

                }, err => {
                    console.error(err);
                });
            }
        })();
    }, [user, client, notificationBase]);

    const initClient = useCallback(async () => {
        await client.startClient({
            initialSyncLimit: 20,
            disablePresence: true,
        });

        window.localStorage.setItem(
            "keys",
            JSON.stringify({
                userId: client.getUserId(),
                deviceId: client.getDeviceId(),
                accessToken: client.getAccessToken(),
            })
        );
    }, [client]);

    const login = useCallback(
        async (username: string, password: string) => {
            if (!username || !password) {
                throw new Error("Please provide username and password");
            }
            const res = await client.loginWithPassword(username, password);
            setUser(res.user_id);
            return initClient();
        },
        [client, initClient]
    );

    const logout = useCallback(async () => {
        await client.logout();
        setUser(null);
        client.stopClient();
        window.localStorage.removeItem("keys");
    }, [client]);

    useEffect(() => {
        client.on("sync", (state, prevState) => {
            console.log(`${prevState} -> ${state}`);
            setState(state);
        });

        client.on("Session.logged_out", () => setUser(null));

        if (client.isLoggedIn()) {
            const user = client.getUserId() ?? null;
            setUser(user);
            initClient();
        }
    }, [client, initClient]);

    useEffect(() => {
        const message = stateMessages[state];
        if (message && changeTopMessages) {
            changeTopMessages({
                object: message,
                method: "add",
            });
            return () => {
                changeTopMessages({
                    object: message,
                    method: "remove",
                });
            };
        }
    }, [state, changeTopMessages]);

    return (
        <MatrixContext.Provider value={{ login, logout, user, client, state }}>
            {children}
        </MatrixContext.Provider>
    );
};
