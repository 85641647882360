import Page from "../Components/Page";
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    styled,
} from "@mui/material";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import {
    PageAuthorQuery,
    PageAuthorQueryVariables,
} from "../graphql/query-types";
import { useParams, Link } from "react-router-dom";
import Paper from "../DataModels/Paper";
import BusinessIcon from "@mui/icons-material/Business";
import IconText from "../Components/IconText";
import useSettings from "../features/settings/hooks/useSettings";

const query = loader("../graphql/PageAuthor.graphql");

const StyledPaperTitle = styled(Typography)({
    marginTop: "1.5rem",
});
const StyledPaperList = styled(List)({
    padding: 0,
});

export default function Author() {
    const params: any = useParams();
    const authorId = parseInt(params.id);

    /* -------------------------------------------------------------------------- */
    /*                                Data Fetching                               */
    /* -------------------------------------------------------------------------- */
    const settings = useSettings();
    const { data } = useQuery<PageAuthorQuery, PageAuthorQueryVariables>(
        query,
        {
            variables: { id: authorId, congress: settings.congress as number },
            returnPartialData: true,
            skip: settings.congress === undefined,
        }
    );

    /* -------------------------------------------------------------------------- */
    /*                                   Header                                   */
    /* -------------------------------------------------------------------------- */
    let name = (data?.user?.first ?? "") + " " + (data?.user?.last ?? "");

    /* --------------------------------- Render --------------------------------- */
    const markupName = <Typography variant="h5">{name}</Typography>;

    /* -------------------------------------------------------------------------- */
    /*                                   Papers                                   */
    /* -------------------------------------------------------------------------- */
    const paperLink = (paper: Paper | null) => {
        return paper ? "/browser/presentation/" + paper.id : "";
    };
    const renderPaperListItems = (paper: Paper | null, i: number) => {
        if (paper) {
            return (
                <Link
                    to={paperLink(paper)}
                    key={i}
                    className="noLinkDecoration"
                >
                    <ListItem button>
                        <ListItemText
                            primary={paper.title}
                            secondary={paper.unique}
                        />
                    </ListItem>
                </Link>
            );
        } else {
            return null;
        }
    };

    /* ------------------------------ Author Papers ----------------------------- */
    const markupAuthorPresentation =
        data?.user?.papers?.map(renderPaperListItems);

    /* ---------------------------- Co-Author Papers ---------------------------- */
    const markupCoAuthorPresentation =
        data?.user?.co_author_papers?.map(renderPaperListItems);

    /* ------------------------ Presenting Author Papers ------------------------ */
    const markupPresentingAuthorPresentation =
        data?.user?.presenting_author_papers?.map(renderPaperListItems);

    return (
        <Page padding>
            {markupName}

            {data?.user?.companys?.map(company => (
                <Link
                    to={"/institutes/" + company?.id}
                    className="noLinkDecoration"
                >
                    <IconText
                        icon={<BusinessIcon />}
                        text={"Company: " + company?.company}
                    />
                </Link>
            ))}

            {markupAuthorPresentation && (
                <>
                    <StyledPaperTitle variant="h6">
                        Author Papers
                    </StyledPaperTitle>
                    <StyledPaperList>
                        {markupAuthorPresentation}
                    </StyledPaperList>
                </>
            )}

            {markupCoAuthorPresentation && (
                <>
                    <StyledPaperTitle variant="h6">
                        Co-Author Papers
                    </StyledPaperTitle>
                    <StyledPaperList>
                        {markupCoAuthorPresentation}
                    </StyledPaperList>
                </>
            )}

            {markupPresentingAuthorPresentation && (
                <>
                    <StyledPaperTitle variant="h6">
                        Presenting Author Papers
                    </StyledPaperTitle>
                    <StyledPaperList>
                        {markupPresentingAuthorPresentation}
                    </StyledPaperList>
                </>
            )}
        </Page>
    );
}
