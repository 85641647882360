import React from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Badge,
    Drawer
} from "@mui/material";
import { Route } from "../Routes";
import { LinkNoDecoration } from "./LinkNoDecoration";
import useAllUnreadCount from "../CustomHooks/matrix/useAllUnreadCount";
import useNavbarHeight from "../CustomHooks/useNavbarHeight";

interface Props {
    routes: Route[];
    show: boolean;
    openSidebar: () => void;
    closeSidebar: () => void;
    isMobile: boolean;
}

export const Sidebar = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const notificationCount = useAllUnreadCount();
    const navbarHeight = useNavbarHeight();

    return (
        <Drawer
            variant="persistent"
            open={props.show}
            PaperProps={{
                ref: ref,
                style: props.isMobile ? {
                    position: "fixed",
                    top: navbarHeight,
                    left: 0,
                    right: 0,
                    bottom: 0
                } : {
                    paddingTop: navbarHeight,
                }
            }}
            //onClose={props.closeSidebar}
            //onOpen={props.openSidebar}
        >
            <List style={{ width: "100%" }}>
                {props.routes.map(({ path, icon: Icon, title, imgsrc }, i) => {
                    if (title) {
                        let finalPath: string;
                        if (Array.isArray(path)) {
                            finalPath = path[path.length - 1];
                        } else {
                            finalPath = path;
                        }

                        return (
                            <LinkNoDecoration
                                to={finalPath}
                                onClick={props.closeSidebar}
                                key={finalPath}
                            >
                                <ListItem button>
                                    {Icon && ((path === "/chat") ? (
                                        <ListItemIcon>
                                            <Badge badgeContent={notificationCount} color="secondary">
                                                <Icon />
                                            </Badge>
                                        </ListItemIcon>
                                    ):(
                                        <ListItemIcon>
                                            <Icon />
                                        </ListItemIcon>
                                    ))}
                                    {imgsrc && (
                                        <ListItemIcon>
                                            <img alt="" src={imgsrc} />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText
                                        primary={title}
                                        inset={!(Icon || imgsrc)}
                                    ></ListItemText>
                                </ListItem>
                            </LinkNoDecoration>
                        );
                    } else {
                        return null;
                    }
                })}
                <ListItem><ListItemText primary="" style={{ height: "1rem" }}></ListItemText></ListItem>
                <ListItem><ListItemText primary="" style={{ height: "1rem" }}></ListItemText></ListItem>
                <ListItem><ListItemText primary="" style={{ height: "1rem" }}></ListItemText></ListItem>
            </List>
        </Drawer>
    );
});