import { useMemo, useState, useContext, useRef, useEffect } from "react";
import useSearch from "../CustomHooks/useSearch";
import Page from "../Components/Page";
import {
    TextField,
    InputAdornment,
    ListItem,
    ListItemText,
    List,
    ListItemIcon,
    Typography,
    styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { loader } from "graphql.macro";
import {
    SearchAbstracts,
    SearchAbstractsVariables,
} from "../graphql/query-types";
import { useQuery } from "@apollo/client";

import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import ClassIcon from "@mui/icons-material/Class";
import BusinessIcon from "@mui/icons-material/Business";
import { FixedSizeList } from "react-window";
import { Link, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import useBoundingRect from "../CustomHooks/useBoundingRect";
import { CacheStatus, CacheStatusContext } from "../App";
import useSettings from "../features/settings/hooks/useSettings";

interface SearchData {
    id: number;
    type: "abstract" | "session" | "user" | "company";
    title: string;
    additonal?: string[];
}

const StyledText = styled(ListItemText)({
    "& .MuiListItemText-primary": {
        maxHeight: "3em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
});

const StyledList = styled(List)({
    paddingTop: 8,
    paddingBottom: 0,
});

const StyledSearchIcon = styled(SearchIcon)({
    fontSize: "256px",
});

const StyledSearchIconWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
    color: "lightgrey",
    alignText: "center",
});

const query = loader("../graphql/SearchAbstracts.graphql");
export default function Search() {
    const { rect: searchRect, ref: searchRef } = useBoundingRect();
    const cacheStatus = useContext(CacheStatusContext);
    const searchEnabled =
        cacheStatus === CacheStatus.READY ||
        cacheStatus === CacheStatus.SHOW_READY;

    const settings = useSettings();
    const { data } = useQuery<SearchAbstracts, SearchAbstractsVariables>(
        query,
        {
            variables: { id: settings.congress },
            skip: !searchEnabled,
        }
    );

    const searchData = useMemo(() => {
        const searchData: SearchData[] = [];
        // Add Abstracts
        data?.congress?.symposiums?.forEach((a) =>
            a?.sessions?.forEach((b) =>
                b?.papers?.forEach((paper) => {
                    if (paper && paper.title) {
                        searchData.push({
                            id: paper.id,
                            type: "abstract",
                            title: paper.title,
                            additonal: [
                                paper.unique ?? "",
                                paper.abstract ?? "",
                            ],
                        });
                    }
                })
            )
        );

        // Add Sessions
        data?.congress?.symposiums?.forEach((symposium) =>
            symposium?.sessions?.forEach((session) => {
                if (session && session.name) {
                    searchData.push({
                        id: session.id,
                        type: "session",
                        title: session.name,
                        additonal: [
                            session.description ?? "",
                            (symposium.short ?? "") + (session.session ?? ""),
                        ],
                    });
                }
            })
        );

        // Add Users
        data?.congress?.authors?.forEach((author) => {
            if (author && author.last && author.first) {
                let name = author.last + ", " + author.first;
                searchData.push({
                    id: author.id,
                    type: "user",
                    title: name,
                });
            }
        });

        // Add Companys
        data?.congress?.companys?.forEach((company) => {
            if (company && company.id && company.company) {
                searchData.push({
                    id: company.id,
                    type: "company",
                    title: company.company,
                });
            }
        });

        return searchData;
    }, [data]);

    const [searchInput, setSearchInput] = useState("");
    const fields = useRef(["title", "unique", "abstract", "test"]);
    const dataS = useSearch({
        data: searchData,
        search: searchInput,
        fields: fields.current,
    });

    const rowLink = (rowData: any) => {
        switch (rowData.type) {
            case "user":
                return "/author/" + rowData.id;
            case "abstract":
                return "/browser/presentation/" + rowData.id;
            case "session":
                return "/browser/session/" + rowData.id;
            case "company":
                return "/institutes/" + rowData.id;
            default:
                return "";
        }
    };

    const Row = (args: any) => {
        const d = dataS.data[args.index];
        return (
            <ListItem
                style={args.style}
                button
                component={Link}
                to={rowLink(d)}
            >
                <ListItemIcon>
                    {d.type === "abstract" && <EventIcon />}
                    {d.type === "session" && <ClassIcon />}
                    {d.type === "user" && <PersonIcon />}
                    {d.type === "company" && <BusinessIcon />}
                </ListItemIcon>
                <StyledText primary={d.title} />
            </ListItem>
        );
    };

    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const q = params.get("q");
        if (searchInput === "") setSearchInput(q ?? "");
    }, []); //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (match) {
            history.replace(
                location.pathname + "?q=" + encodeURIComponent(searchInput)
            );
        }
    }, [searchInput, history, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!searchEnabled) {
        return (
            <Page padding title="Search">
                <Typography>
                    The search is only available if the offline mode is enabled
                </Typography>
            </Page>
        );
    }

    if (dataS.indexReady) {
        return (
            <Page padding title="Search">
                <div ref={searchRef}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        fullWidth
                    />
                </div>
                {searchInput ? (
                    <StyledList
                        sx={{
                            height: `calc(100vh - ${
                                (searchRect?.bottom ?? 0) + 8
                            }px)`,
                        }}
                    >
                        <AutoSizer>
                            {(size) => (
                                <FixedSizeList
                                    height={size.height}
                                    itemSize={64}
                                    itemCount={dataS.data.length}
                                    width={size.width}
                                >
                                    {Row}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </StyledList>
                ) : (
                    <StyledSearchIconWrapper>
                        <StyledSearchIcon />
                        <Typography align="center">
                            No results yet
                            <br />
                            Please provide a search query
                        </Typography>
                    </StyledSearchIconWrapper>
                )}
            </Page>
        );
    } else {
        return <Page>Loading</Page>;
    }
}
