import {
    useContext,
    useEffect
} from "react";
import { NavBarOptionsContext } from "../Components/Navbar";
import { BreadcrumbItem } from "../Components/Breadcrumb";


export function useBreadcrumbs(items: BreadcrumbItem[]) {
    const [, setBreadcrumbs] = useContext(NavBarOptionsContext).breadcrumbs;

    useEffect(() => {
        setBreadcrumbs(items);

        return () => {
            setBreadcrumbs([]);
        };
    }, [items, setBreadcrumbs]);
}
