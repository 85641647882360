import { useState, useEffect } from "react";
import Page from "../Components/Page";
import useSettings from "../features/settings/hooks/useSettings";
import { getRecapsByCongress } from "../util/RecapsService";
import Recap, { RecapInterface } from "../Components/Recap";
import React from "react";
import RecapsDialogPreview from "../Components/RecapDialogPreview";
import { Button, Divider } from "@mui/material";


export default function RecapsPage({ congress }: { congress: number }) {
    const [recaps, setRecaps] = useState([]);
    const settings = useSettings();

    useEffect(() => {
        getRecapsByCongress(congress)
            .then((res) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Normalize today to start of day for comparison

                const filteredAndSortedRecaps = res
                    .filter((recap: RecapInterface) => {
                        const recapDate = new Date(recap.date);
                        recapDate.setHours(0, 0, 0, 0); // Normalize recap date for comparison
                        return recapDate >= today; // Include recaps with date today or before
                    })
                    .sort((a: RecapInterface, b: RecapInterface) => {
                        // Assuming recap.date is in a format that Date.parse() can handle
                        const dateA = Date.parse(a.date);
                        const dateB = Date.parse(b.date);
                        return dateB - dateA; // For descending order
                    });
                setRecaps(filteredAndSortedRecaps);
            })
            .catch((err) => console.error(err));
    }, [congress, settings.congress]);

    return (
        <Page fullSize title="What’s On Today Preview">
            <div className="newsContentContainer">
                {Array.isArray(recaps) && recaps.map((recap: RecapInterface, index: number) => (
                    <React.Fragment key={recap.id}>
                        <Recap recap={recap} />
                        <RecapsDialogPreview recap={recap} />
                        {index !== recaps.length - 1 && <Divider sx={{ borderWidth: 2 }} />}
                    </React.Fragment>
                ))}
            </div>
        </Page>
    );
}