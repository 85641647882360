import { Box, Card, CardContent, CardMedia, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled } from "@mui/material";
import { commonSettings } from "./settings";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CongressSettings } from "./features/settings/context/SettingsContext";
import useOfflineActive from "./features/settings/hooks/useOfflineActive";
import DeleteIcon from '@mui/icons-material/Delete';
import { useRef, useState } from "react";
import pd from "./shared/preventDefault";

const CongressTitle = styled("h2")({
    color: "#000",
    fontWeight: "600",
    fontSize: "1.3rem",
    margin: "0",
    textAlign: "start",
    verticalAlign: "center",
});

const CongressSubTitle = styled("h3")({
    color: "#777",
    fontWeight: "600",
    fontSize: "1.1rem",
    margin: "0",
    marginTop: ".5rem",
    textAlign: "start",
    verticalAlign: "center",
});

const FlexCenter = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
});

interface Props {
    settings: CongressSettings;
}

export default function CongressSelectorItem({ settings }: Props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isOffline, setIsOffline] = useOfflineActive(settings.congress);
    const anchor = useRef<HTMLButtonElement>(null);

    return (
        <Card
            component="a"
            href={`/${settings.slug}`}
            className="noLinkDecoration"
            key={settings.slug}
        >
            <CardMedia
                component="img"
                image={`${commonSettings.dataServer}/upload/${settings.icon}`}
                alt=""
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                }}
            >
                <CardContent>
                    <CongressTitle
                        dangerouslySetInnerHTML={{
                            __html: settings.name,
                        }}
                    />
                    <CongressSubTitle
                        dangerouslySetInnerHTML={{
                            __html: settings.subtitle,
                        }}
                    />
                </CardContent>
                <IconButton
                    onClick={pd(() => setMenuOpen(true))}
                    sx={{ mr: 1, mt: 1 }}
                    ref={anchor}
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>
            <Menu open={menuOpen} anchorEl={anchor.current} onClose={() => setMenuOpen(false)}>
                <MenuItem onClick={() => setIsOffline(false)}>
                    <ListItemIcon><DeleteIcon /></ListItemIcon>
                    <ListItemText>Remove Offline data</ListItemText>
                </MenuItem>
            </Menu>
        </Card>
    );
}
