import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useSpring, animated, AnimationResult } from "react-spring";
import { useDrag } from "react-use-gesture";
import Paper from "../../DataModels/Paper";

const SWIPE_DISTANCE = 200;

interface Props {
    paper?: Paper | null;
    onSwipe?: (e: SwipeCardEvent) => void;
}

export interface SwipeCardEvent {
    direction: "left" | "right";
}

export default function SwipeCard(props: Props) {
    const animationListener = (result: AnimationResult) => {
        if (result.finished && (result.value as any).x !== 0 && props.onSwipe) {
            props.onSwipe({
                direction: (result.value as any).x > 0 ? "right" : "left",
            });
        }
    };

    const [{ x }, api] = useSpring(() => ({
        to: { x: 0 },
        onRest: animationListener,
    }));

    const bind = useDrag(({ down, movement }) => {
        const move_x = movement[0];

        if (down) {
            api.set({ x: move_x });
        } else {
            if (move_x > SWIPE_DISTANCE) {
                api.start({ x: window.innerWidth });
            } else if (move_x < -SWIPE_DISTANCE) {
                api.start({ x: -window.innerWidth });
            } else {
                api.start({ x: 0 });
            }
        }
    });

    return (
        <animated.div
            {...bind()}
            style={{ touchAction: "none", x, position: "absolute" }}
        >
            <Card>
                <CardHeader
                    subheader={props.paper?.unique}
                    title={props.paper?.title}
                />
                <CardContent>
                    <Typography>{props.paper?.abstract}</Typography>
                </CardContent>
            </Card>
        </animated.div>
    );
}
