import useLocalStorage from "../../../CustomHooks/useLocalStorage";
import localForage from "localforage";

type OfflineSave = { [key: number]: boolean|null };

export default function useOfflineActive(congressId?: number): [boolean|null, (value: boolean|null) => void] {
    const [offlineIds, setOfflineIds] = useLocalStorage<OfflineSave>("offline", {});
    const isOffline = (congressId ? offlineIds[congressId] : null) ?? null;

    const setIsOffline = (value: boolean|null) => {
        if (congressId) {
            if (value) {
                setOfflineIds(a => ({ ...a, [congressId]: true }));
            } else {
                localForage.removeItem(`${congressId}-cache`);
                setOfflineIds(({[congressId]: __, ...a }) => a);
            }
        }
    }

    return [isOffline, setIsOffline];
}