import { Divider } from "@mui/material";
import ReactHtmlParser from "react-html-parser";

export interface RecapInterface {
  id: number;
  title: string;
  content: string;
  date: string;
  congress: number;
}

interface RecapProps {
  recap: RecapInterface;
}

const Recap: React.FC<RecapProps> = ({ recap }) => {
  return (
    <div key={recap?.id ?? '1'} className="newsContentContainer">
      <h1>{recap?.title}</h1>
      <Divider textAlign="left">{recap?.date}</Divider>
      <div className="newsContent">{ReactHtmlParser(recap?.content ?? '')}</div>
    </div>
  )
};

export default Recap;